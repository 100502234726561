import { defaultMessengerState } from "../state/messenger-state";
import { messengerActions } from "../actions/messenger-actions";

const handlers = {
  [messengerActions.SHOW_MESSENGER]: (state, payload) => {
    return Object.assign({}, state, {
      open: true,
      message: payload,
    });
  },

  [messengerActions.HIDE_MESSENGER]: (state, payload) => {
    return Object.assign({}, state, {
      open: false,
    });
  },
};

const messengerReducer = (state = defaultMessengerState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default messengerReducer;
