export const BASE_API_VERSION = "/api/v1";
export const BASE_JOBS_API = `${BASE_API_VERSION}/jobs`;
export const BASE_COMPANIES_API = `${BASE_API_VERSION}/companies`;
export const AUTH_BASE = "/a";
export const CREATE_PAYMENT_INTENT = `${BASE_JOBS_API}/generate_payment_intent`;

export const paths = {
  home: "/",
  about: "/about",
  contact: "/contact",
  terms: "/terms",
  privacy: "/privacy",
  post_job: "/remote-jobs/new",
  all_jobs: "/remote-jobs",
  developer_jobs: "/remote-developer-jobs",
  marketing_jobs: "/remote-marketing-jobs",
  manager_jobs: "/remote-manager-exec-jobs",
  design_jobs: "/remote-design-jobs",
  writing_jobs: "/remote-writing-jobs",
  recruiter_jobs: "/remote-recruiter-jobs",
  project_manager_jobs: "/remote-project-manager-jobs",
  sales_jobs: "/remote-sales-jobs",
  editing_jobs: "/remote-editing-jobs",
  copywriter_jobs: "/remote-copywriter-jobs",
  hr_jobs: "/remote-hr-jobs",
  social_media_jobs: "/remote-social-media-jobs",
  seo_jobs: "/remote-seo-jobs",
  customer_service_jobs: "/remote-customer-service-jobs",
  devops_sysadmin_jobs: "/remote-devops-sysadmin-jobs",
  favourite_jobs: "/favourite-remote-jobs",
  thank_you: "/thank-you",
  partners: "/partners",
  credits: "/credits",
  articles: "/articles",
  resume_skills: "/skills-for-resume",
  resources: "/resources",
  subscribed: "/subscribed",
  why_list: "/list-your-remote-role",
  companies: "/remote-companies",
  people: "/remote-workers",
  landing: "/l",
  dashboard: "/a/dashboard",
  sign_up: "/a/sign-up",
  sign_in: "/a/sign-in",
  subscribe: "/a/subscribe",
  account: "/a/account",
  forgotten_password: "/a/forgot",
  reset_password: "/a/reset",
  power_search: "/power-search",
};
