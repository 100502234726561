export const defaultResourceState = {
  uid: '',
  title: '',
  summary: '', 
  description: '',
  logo: '',
  hero_image_mobile: '',
  hero_image_desktop: '',
  footer_image_one: '',
  footer_image_two: '',
  footer_image_three: '',
  review: '',
  faqs: [],
  category: '',
  url: '' 
};