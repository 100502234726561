import styled from "styled-components";
import { media } from "../../../constants/theme";

export const FilterLabel = styled.div`
  display: none;
  ${media.desktop`
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.03em;
    display:block;
  `}
`;
