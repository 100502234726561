import { defaultFilterState } from "../state/filter-state";
import { filterActions } from "../actions/filter-actions";

const newFiltersLength = (newFilters, prevFilters, filterCat) => {
  return Object.keys(newFilters(prevFilters, filterCat)).length;
};

const handlers = {
  [filterActions.ADD_FILTER]: (state, payload) => {
    const category = payload.category;
    const filterCat = payload.filterCat;
    const data = payload.data;

    return {
      ...state,
      [category]: {
        ...state[category],
        filtersApplied: true,
        filters: {
          ...state[category].filters,
          [filterCat]: data,
        },
      },
    };
  },

  [filterActions.REMOVE_FILTER]: (state, payload) => {
    const category = payload.category;
    const filterCat = payload.filterCat;
    const prevFilters = state[category].filters;

    const newFilters = (obj, prop) => {
      let { [prop]: omit, ...res } = obj;
      return res;
    };

    return {
      ...state,
      [category]: {
        ...state[category],
        filtersApplied:
          newFiltersLength(newFilters, prevFilters, filterCat) > 0
            ? true
            : false,
        filters: newFilters(prevFilters, filterCat),
      },
    };
  },

  [filterActions.CLEAR_FILTERS]: (state, payload) => {
    const category = payload;

    return {
      ...state,
      [category]: {
        filtersApplied: false,
        filters: {},
      },
    };
  },

  [filterActions.ADD_TO_FILTERS]: (state, payload) => {
    const category = payload.category;
    const filterCat = payload.filterCat;
    const data = payload.data;
    const prevFilters = state[category].filters;

    const addToFilters = () => {
      if (prevFilters[filterCat]) {
        return [...prevFilters[filterCat], data];
      } else {
        return [data];
      }
    };

    return {
      ...state,
      [category]: {
        ...state[category],
        filtersApplied: true,
        filters: {
          ...state[category].filters,
          [filterCat]: addToFilters(),
        },
      },
    };
  },

  [filterActions.REMOVE_FROM_FILTERS]: (state, payload) => {
    const category = payload.category;
    const filterCat = payload.filterCat;
    const data = payload.data;
    const prevFilters = state[category].filters;

    const removeAll = (prevFilters, filterCat) => {
      let { [filterCat]: omit, ...newFilters } = prevFilters;
      return newFilters;
    };

    const removeOne = (prevFilters, filterCat) => {
      const filteredLocations = prevFilters[filterCat].filter(
        (f) => f !== data
      );
      let newFilters = { ...prevFilters, [filterCat]: filteredLocations };
      return newFilters;
    };

    const removeFromFilters = () => {
      if (prevFilters[filterCat].length === 1) {
        return removeAll(prevFilters, filterCat);
      } else {
        return removeOne(prevFilters, filterCat);
      }
    };

    return {
      ...state,
      [category]: {
        ...state[category],
        filtersApplied:
          newFiltersLength(removeFromFilters, prevFilters, filterCat) > 0
            ? true
            : false,
        filters: removeFromFilters(),
      },
    };
  },
};

const filterReducer = (state = defaultFilterState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default filterReducer;
