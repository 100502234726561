import React from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../constants/theme";
import { withRouter } from "react-router";
import { paths } from "../../../constants/paths";
import { Link } from "react-router-dom";
import { TrackClick } from "../../../utils/analytics";
// import { PARTNER_PATHS } from "../../../constants/partner-paths";

const sharedStyles = () => css`
  text-align: center;
  display: flex;
  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${(props) =>
    props.red ? colors.primaryRed : colors.backgroundBlue};
`;

const Copy = styled(Link)`
  ${sharedStyles}
`;

// const OutboundCopy = styled.a`
//   ${sharedStyles}
// `;

const UnderLine = styled.span`
  text-decoration: underline;
`;

const isHidden = (path) => {
  if (path === paths.sign_in) return true;
  if (path === paths.sign_up) return true;
  if (path === paths.power_search) return true;
  if (path === paths.post_job) return true;
  if (path === paths.forgotten_password) return true;
  if (path === paths.reset_password) return true;
  if (path === paths.account) return true;
  if (path === paths.dashboard) return true;
  return false;
};

const renderContent = (path) => {
  // if (path === paths.customer_service_jobs) {
  //   return (
  //     <Wrapper>
  //       <OutboundCopy
  //         href={PARTNER_PATHS.CUSTOMEREXPERTS_PROFESSIONALS}
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         onClick={() =>
  //           TrackClick({
  //             category: "PortfolioBusiness",
  //             action: `CustomerExperts - ${path} - Banner`,
  //             label: "CustomerExperts - Banner",
  //           })
  //         }
  //       >
  //         <UnderLine>
  //           Join CustomerExperts and have companies apply to you!
  //         </UnderLine>
  //       </OutboundCopy>
  //     </Wrapper>
  //   );
  // }

  return (
    <Wrapper>
      <Copy
        to={paths.power_search}
        onClick={() =>
          TrackClick({
            category: "PowerSearch",
            action: `PowerSearch - ${path} - Banner`,
            label: "PowerSearch - Banner",
          })
        }
      >
        <UnderLine>Discover 2000+ hidden remote jobs</UnderLine> ⚡️
      </Copy>
    </Wrapper>
  );
};

export const Banner = ({ location }) => {
  const path = location.pathname;

  if (isHidden(path)) return null;

  return <>{renderContent(path)}</>;
};

export default withRouter(Banner);
