import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import articleReducer from "./reducers/article-reducer";
import companiesReducer from "./reducers/companies-reducer";
import companyReducer from "./reducers/company-reducer";
import emailFormReducer from "./reducers/email-form-reducer";
import favouriteReducer from "./reducers/favourite-reducer";
import filterReducer from "./reducers/filter-reducer";
import homeJobsReducer from "./reducers/home-job-reducer";
import jobsReducer from "./reducers/jobs-reducer";
import loadingReducer from "./reducers/loading-reducer";
import messengerReducer from "./reducers/messenger-reducer";
import modalReducer from "./reducers/modal-reducer";
import newJobReducer from "./reducers/new-job-reducer";
import personReducer from "./reducers/person-reducer";
import resourceReducer from "./reducers/resource-reducer";
import singleJobReducer from "./reducers/single-job-reducer";
import userReducer from "./reducers/user-reducer";
import paymentMethodsReducer from "./reducers/payment-methods-reducer";
import subscriptionReducer from "./reducers/subscription-reducer";
import premiumJobsReducer from "./reducers/premium-jobs-reducer";
import paymentReducer from "./reducers/payment-reducer";
import paymentIntentReducer from "./reducers/payment-intent-reducer";

const appReducer = combineReducers({
  articleState: articleReducer,
  companiesState: companiesReducer,
  companyState: companyReducer,
  emailFormState: emailFormReducer,
  favouriteState: favouriteReducer,
  filterState: filterReducer,
  homeJobsState: homeJobsReducer,
  jobsState: jobsReducer,
  loadingState: loadingReducer,
  messengerState: messengerReducer,
  modalState: modalReducer,
  newJobState: newJobReducer,
  personState: personReducer,
  resourceState: resourceReducer,
  singleJobState: singleJobReducer,
  userState: userReducer,
  paymentMethodsState: paymentMethodsReducer,
  subscriptionState: subscriptionReducer,
  premiumJobs: premiumJobsReducer,
  paymentState: paymentReducer,
  intentState: paymentIntentReducer,
  router: routerReducer,
});

export const rootReducer = (state, action) => {
  return appReducer(state, action);
};
