import React, { Component } from "react";
import styled from "styled-components";
import NavbarLinks from "../navbar-links";
import { Logo } from "../../atoms/logo";
import { MenuDraw } from "../menu-draw";
import { paths } from "../../../constants/paths";
import { Link } from "react-router-dom";

const NavWrapper = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px;
  width: 100%;
  z-index: 7;

  ${(props) =>
    props.isNewJob &&
    `
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 1px solid #f4f6f7;
    position: fixed;
    background-color: #fff;
  `}
`;

export class Navbar extends Component {
  render() {
    const isNewJob = this.props.page.indexOf(paths.post_job) >= 0;
    const isDashboard = this.props.page === paths.dashboard;
    const isAccount = this.props.page === paths.account;

    if (isDashboard || isAccount) return null;

    return (
      <NavWrapper isNewJob={isNewJob}>
        <MenuDraw />
        <Link to={paths.home}>
          <Logo />
        </Link>
        {(!isDashboard || !isAccount) && <NavbarLinks />}
      </NavWrapper>
    );
  }
}
