import React from "react";
import { ErrorMessage } from "../error-message";
import { SelectableChip } from "../chip";

export const RadioChips = props => {
  const { chips, click, validate, dark } = props;

  return (
    <div>
      {chips.map((chip, i) => {
        return (
          <SelectableChip
            dark={dark ? true : false}
            key={i}
            selected={chip.selected}
            label={chip.label}
            click={() => click(chip)}
          />
        );
      })}
      {validate ? <ErrorMessage message={validate.message} /> : null}
    </div>
  );
};
