import { APPEND_ID } from "../constants/ad-constants";

export const appendAdScript = () => {
  const append_location = document.querySelector(`#${APPEND_ID}`);
  const script = document.createElement("script");
  script.src =
    "https://cdn.carbonads.com/carbon.js?serve=CE7I623I&placement=justremoteco";
  script.id = "_carbonads_js";
  script.async = true;

  append_location.appendChild(script);
};
