import { defaultMetaState } from "./meta-state";

export const defaultSubscriptionEntityState = {
  isActive: true,
  nextPayment: "",
};

export const defaultSubscriptionState = {
  entity: defaultSubscriptionEntityState,
  meta: defaultMetaState,
};
