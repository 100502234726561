import { defaultMetaState } from "./meta-state";

export const defaultPremiumJobsEntityState = {
  jobs: [],
  count: 0,
};

export const defaultPremiumJobsState = {
  entity: defaultPremiumJobsEntityState,
  meta: defaultMetaState,
};
