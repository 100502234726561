import dynamicDeveloperJobs from "../app/pages/dynamic-developer-jobs";
import { withAnalytics } from "./analytics";
import { skills } from "../constants/dynamic-developer-route-skills";

export const generateDeveloperRoutes = () =>
  skills.map((skill) => {
    let path = skill;
    if (skill === "react") path = "react-developer";
    if (skill === "java") path = "java-developer";
    if (skill === "ios") path = "ios-developer";
    if (skill === "node") path = "node-js";

    return {
      path: `/${path}-jobs`,
      exact: true,
      component: withAnalytics(dynamicDeveloperJobs),
      type: `${skill} jobs`,
      skill: skill.split("-").join(" "),
    };
  });

export const generateDynamicDeveloperFilters = () => {
  return skills.map((skill) => {
    const skillKey = skill.split("-").join("");
    return {
      [skillKey]: {
        filtersApplied: false,
        filters: {},
      },
    };
  });
};
