import React, { useState } from "react";
import styled from "styled-components";
import { FormField } from "../../atoms/form-field";
import { Label } from "../../atoms/label";
import { Input } from "../../atoms/input";
import { colors, media } from "../../../constants/theme";
import { updatePassword } from "../../../store/thunks/update-password";
import { useDispatch } from "react-redux";
import { UpdatePasswordValidation } from "../../../form-validations/update-password-validation";
import { showMessage } from "../../../store/actions/messenger-actions";

const Wrapper = styled.div`
  min-width: 260px;
  ${media.desktop`
    min-width: 300px;
  `}
`;

const Form = styled.form`
  background-color: ${colors.white};
  border-radius: 5px;
`;

const Button = styled.button`
  font-family: "Europa", sans-serif;
  font-size: 16px;
  background: ${colors.primaryRed};
  color: #fff;
  font-weight: 600;
  border: 0;
  width: 140px;
  height: 50px;
  line-height: 16px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
  &[disabled] {
    opacity: 0.5;
  }
`;

export const UpdatePasswordModal = () => {
  const dispatch = useDispatch();
  const [validation, setValidation] = useState();
  const [state, setState] = useState({
    currentPassword: {
      value: "",
      error: "",
    },
    newPassword: {
      value: "",
      error: "",
    },
    confirmPassword: {
      value: "",
      error: "",
    },
  });
  const { currentPassword, newPassword, confirmPassword } = state;

  const validate = (field) => {
    return validation && validation[field].isInvalid ? validation[field] : null;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword.value !== confirmPassword.value) {
      dispatch(
        showMessage("Please ensure new password and confirm password match")
      );
      return;
    }

    const valid = UpdatePasswordValidation.validate({
      currentPassword: currentPassword.value,
      newPassword: newPassword.value,
      confirmPassword: confirmPassword.value,
    });
    setValidation(valid);

    if (valid.isValid === false) {
      return;
    }

    const data = {
      current_password: currentPassword.value,
      user: {
        password: newPassword.value,
        password_confirmation: confirmPassword.value,
      },
    };

    dispatch(updatePassword(data));
  };

  const handleChange = (e) => {
    const { name, value } = e.currentTarget;
    setState({ ...state, [name]: { value, error: "" } });
  };

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit}>
        <FormField>
          <Label htmlFor="currentPassword" title="Current Password:" />
          <Input
            name="currentPassword"
            type="password"
            validate={validate("currentPassword")}
            value={currentPassword.value}
            change={handleChange}
          />
        </FormField>
        <FormField>
          <Label htmlFor="newPassword" title="New Password:" />
          <Input
            name="newPassword"
            type="password"
            validate={validate("newPassword")}
            value={newPassword.value}
            change={handleChange}
          />
        </FormField>
        <FormField>
          <Label htmlFor="confirmPassword" title="Confirm New Password:" />
          <Input
            type="password"
            name="confirmPassword"
            validate={validate("confirmPassword")}
            value={confirmPassword.value}
            change={handleChange}
          />
        </FormField>
        <Button>Save Changes</Button>
      </Form>
    </Wrapper>
  );
};
