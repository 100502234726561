import { defaultMetaState } from "./meta-state";

export const defaultHomeJobsEntityState = {
  jobs: null
};

export const defaultHomeJobsState = {
  entity: defaultHomeJobsEntityState,
  meta: defaultMetaState
};
