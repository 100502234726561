import {
  defaultCompanyState,
  defaultCompanyEntityState
} from "../state/company-state";
import { companyActions } from "../actions/company-actions";
import { defaultMetaState, metaStatuses } from "../state/meta-state";

const handlers = {
  [companyActions.FETCH_COMPANY]: state => {
    return Object.assign({}, state, {
      entity: defaultCompanyEntityState,
      meta: {
        ...defaultMetaState,
        status: metaStatuses.LOADING
      }
    });
  },

  [companyActions.FETCH_COMPANY_ERROR]: (state, payload) => {
    return Object.assign({}, state, {
      entity: defaultCompanyEntityState,
      meta: {
        status: metaStatuses.FAILURE,
        error: payload.message
      }
    });
  },

  [companyActions.FETCH_COMPANY_SUCCESS]: (state, payload) => {
    return Object.assign({}, state, {
      entity: {
        company: payload
      },
      meta: {
        status: metaStatuses.SUCCESS,
        error: ""
      }
    });
  },

  [companyActions.RESET_COMPANY]: state => {
    return Object.assign({}, state, {
      entity: defaultCompanyEntityState,
      meta: defaultMetaState
    });
  }
};

const companyReducer = (state = defaultCompanyState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default companyReducer;
