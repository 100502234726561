import { countryFilter } from "../utils/dynamic-location-helpers";

const countryLocations = [
  "canada",
  "united kingdom",
  "united states",
  "france",
  "sweden",
  "norway",
  "belgium",
  "germany",
  "australia",
  "poland",
  "ukraine",
  "estonia",
  "latvia",
  "brazil",
  "austria",
  "malta",
  "portugal",
  "spain",
  "italy",
  "serbia",
  "finland",
  "denmark",
  "greece",
  "romania",
  "netherlands",
  "japan",
  "vietnam",
  "china",
  "ireland",
  "colombia",
];

const capitalizeAll = (str) => str.replace(/\b\w/g, (l) => l.toUpperCase());

const removeSpacesAndCapitalize = (str) => {
  const split = str.split(" ");
  const capitalize = split.map((s) => capitalizeAll(s));
  const join = capitalize.join("");
  return join;
};

const hyphenate = (str) => str.split(" ").join("-");

export const locations = countryLocations.map((location) => {
  return {
    nameLowercase: location,
    nameUppercase: capitalizeAll(location),
    backendName: capitalizeAll(location),
    filterCategory: `country${removeSpacesAndCapitalize(location)}`,
    path: `/remote-jobs-in-${hyphenate(location)}`,
    filterJobs: (jobs) =>
      jobs.filter((job) => countryFilter(job, capitalizeAll(location))),
  };
});
