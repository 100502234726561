import { paymentActions } from "../actions/payment-actions";
import { defaultPaymentState } from "../state/payment-state";
import { metaStatuses, defaultMetaState } from "../state/meta-state";

const handlers = {
  [paymentActions.MAKE_PAYMENT]: (state) => {
    return Object.assign({}, state, {
      meta: {
        ...defaultMetaState,
        status: metaStatuses.LOADING,
      },
    });
  },

  [paymentActions.MAKE_PAYMENT_ERROR]: (state) => {
    return Object.assign({}, state, {
      meta: {
        status: metaStatuses.FAILURE,
      },
    });
  },
  [paymentActions.MAKE_PAYMENT_SUCCESS]: (state) => {
    return Object.assign({}, state, {
      meta: {
        ...defaultMetaState,
        status: metaStatuses.SUCCESS,
      },
    });
  },
};

const paymentReducer = (state = defaultPaymentState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default paymentReducer;
