import { defaultFavouriteState } from "../state/favourite-state";
import { favouriteActions } from "../actions/favourite-actions";

const toggleJob = (payload, prevState) => {
  if (prevState.find(data => data.id === payload.id)) {
    return prevState.filter(data => data.id !== payload.id);
  } else {
    return [...prevState, payload];
  }
};

const handlers = {
  [favouriteActions.SET_FAVOURITE]: (state, payload) => {
    let prevState = state.favourites;
    return {
      ...state,
      favourites: toggleJob(payload, prevState)
    };
  },
  [favouriteActions.SET_LOCAL_STATE_FAVOURITES]: (state, payload) => {
    return Object.assign({}, state, payload);
  },
  [favouriteActions.FETCH_SINGLE_FAVOURITE_JOB_ERROR]: (state, payload) => {
    return Object.assign({}, state, {
      error: payload
    });
  },

  [favouriteActions.FETCH_SINGLE_FAVOURITE_JOB_SUCCESS]: (state, payload) => {
    return Object.assign({}, state, {
      favouriteJob: payload
    });
  }
};

const favouriteReducer = (state = defaultFavouriteState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default favouriteReducer;
