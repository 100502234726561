export const messengerActions = {
  SHOW_MESSENGER: `SHOW_MESSENGER`,
  HIDE_MESSENGER: `HIDE_MESSENGER`
};

export const showMessage = message => ({
  type: messengerActions.SHOW_MESSENGER,
  payload: message
});

export const hideMessage = () => ({
  type: messengerActions.HIDE_MESSENGER,
});
