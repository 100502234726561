import { generateDynamicDeveloperFilters } from "../../utils/dynamic-developer-route-helpers";
import { generateDynamicLocationFilters } from "../../utils/dynamic-location-helpers";
export const defaultFilterState = Object.assign(
  {},
  {
    all: {
      filtersApplied: false,
      filters: {},
    },
    developer: {
      filtersApplied: false,
      filters: {},
    },
    marketing: {
      filtersApplied: false,
      filters: {},
    },
    manager: {
      filtersApplied: false,
      filters: {},
    },
    design: {
      filtersApplied: false,
      filters: {},
    },
    writing: {
      filtersApplied: false,
      filters: {},
    },
    customerservice: {
      filtersApplied: false,
      filters: {},
    },
    devopsandsysadmin: {
      filtersApplied: false,
      filters: {},
    },
  },
  ...generateDynamicDeveloperFilters(),
  ...generateDynamicLocationFilters()
);
