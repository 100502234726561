import React from "react";
import styled from "styled-components";

const Wrapper = styled.div``;

export const UpdatePaymentMethodModal = (props) => {
  return (
    <Wrapper>
      <div>Update Payment Modal</div>
    </Wrapper>
  );
};
