import App from "../app";
import loadable from "@loadable/component";
import { paths } from "./../constants/paths";
import { withAnalytics } from "../utils/analytics";
import { generateDeveloperRoutes } from "../utils/dynamic-developer-route-helpers";
import { generateDynamicLocationRoutes } from "../utils/dynamic-location-helpers";

const Home = loadable(() => import("../app/pages/home"));
const About = loadable(() => import("../app/pages/about"));
const Contact = loadable(() => import("../app/pages/contact"));
const Partners = loadable(() => import("../app/pages/partners"));
const Terms = loadable(() => import("../app/pages/terms"));
const Privacy = loadable(() => import("../app/pages/privacy"));
const Credits = loadable(() => import("../app/pages/credits"));
const Dashboard = loadable(() => import("../app/pages/dashboard"));
const SignIn = loadable(() => import("../app/pages/sign-in"));
const SignUp = loadable(() => import("../app/pages/sign-up"));
// const Subscribe = loadable(() => import("../app/pages/subscribe"));
const Account = loadable(() => import("../app/pages/account"));
const ForgottenPassword = loadable(() =>
  import("../app/pages/forgotten-password")
);
const PowerSearch = loadable(() => import("../app/pages/power-search"));
const ResetPassword = loadable(() => import("../app/pages/reset-password"));
const Article = loadable(() => import("../app/pages/article/articleContainer"));
const Subscribed = loadable(() => import("../app/pages/subscribed"));
const NewJob = loadable(() => import("../app/pages/new-job"));
const ThankYou = loadable(() => import("../app/pages/thanks"));
const ErrorPage = loadable(() => import("../app/pages/error"));
const Listing = loadable(() => import("../app/pages/listing"));
const Landing = loadable(() => import("../app/pages/landing"));
const LandingThanks = loadable(() => import("../app/pages/landing-thanks"));
const Favourites = loadable(() => import("../app/pages/new-favourites"));
const Person = loadable(() => import("../app/pages/person/personContainer"));
const Company = loadable(() => import("../app/pages/company/companyContainer"));
const Companies = loadable(() =>
  import("../app/pages/companies/companiesContainer")
);
const Resources = loadable(() =>
  import("../app/pages/resources/resourcesContainer")
);
const Resource = loadable(() =>
  import("../app/pages/resource/resourceContainer")
);
const ShowAllJobsContainer = loadable(() =>
  import("../app/pages/show-jobs/showAllJobsContainer")
);
const ShowMarketingJobsContainer = loadable(() =>
  import("../app/pages/show-jobs/showMarketingJobsContainer")
);
const ShowManagerJobsContainer = loadable(() =>
  import("../app/pages/show-jobs/showManagerJobsContainer")
);
const ShowDesignJobsContainer = loadable(() =>
  import("../app/pages/show-jobs/showDesignJobsContainer")
);
const ShowWritingJobsContainer = loadable(() =>
  import("../app/pages/show-jobs/showWritingJobsContainer")
);
const ShowCustomerServiceJobsContainer = loadable(() =>
  import("../app/pages/show-jobs/showCustomerServiceJobsContainer")
);
const ShowDevopsSysAdminJobsContainer = loadable(() =>
  import("../app/pages/show-jobs/showDevopsSysAdminJobsContainer")
);
const ShowSocialMediaJobsContainer = loadable(() =>
  import("../app/pages/show-jobs/showSocialMediaJobsContainer")
);
const ShowSeoJobsContainer = loadable(() =>
  import("../app/pages/show-jobs/showSeoJobsContainer")
);
const ShowRecruiterJobsContainer = loadable(() =>
  import("../app/pages/show-jobs/showRecruiterJobsContainer")
);
const ShowHRJobsContainer = loadable(() =>
  import("../app/pages/show-jobs/showHrJobsContainer")
);
const ShowSalesJobsContainer = loadable(() =>
  import("../app/pages/show-jobs/showSalesJobsContainer")
);
const ShowEditingJobsContainer = loadable(() =>
  import("../app/pages/show-jobs/showEditingJobsContainer")
);
const ShowCopywriterJobsContainer = loadable(() =>
  import("../app/pages/show-jobs/showCopywriterJobsContainer")
);
const ShowProjectManagerJobsContainer = loadable(() =>
  import("../app/pages/show-jobs/showProjectManagerJobsContainer")
);
const ShowDevJobsContainer = loadable(() =>
  import("../app/pages/show-jobs/showDevJobsContainer")
);
const ShowSingleDevJobContainer = loadable(() =>
  import("../app/pages/show-single-job/showSingleDevJobContainer")
);
const ShowSingleMarketingJobContainer = loadable(() =>
  import("../app/pages/show-single-job/showSingleMarketingJobContainer")
);
const ShowSingleManagerJobContainer = loadable(() =>
  import("../app/pages/show-single-job/showSingleManagerJobContainer")
);
const ShowSingleDesignJobContainer = loadable(() =>
  import("../app/pages/show-single-job/showSingleDesignJobContainer")
);
const ShowSingleWritingJobContainer = loadable(() =>
  import("../app/pages/show-single-job/showSingleWritingJobContainer")
);
const ShowSingleCustomerServiceJobContainer = loadable(() =>
  import("../app/pages/show-single-job/showSingleCustomerServiceJobContainer")
);
const ShowSingleDevopsSysAdminJobContainer = loadable(() =>
  import("../app/pages/show-single-job/showSingleDevopsSysAdminJobContainer")
);

const Routes = [
  {
    component: App,
    routes: [
      {
        path: paths.home,
        exact: true,
        component: withAnalytics(Home),
        type: "homepage",
      },
      {
        path: paths.dashboard,
        exact: true,
        component: withAnalytics(Dashboard),
        type: "dashboard",
      },
      {
        path: paths.sign_in,
        exact: true,
        component: withAnalytics(SignIn),
        type: "sign in",
      },
      {
        path: paths.sign_up,
        exact: true,
        component: withAnalytics(SignUp),
        type: "sign up",
      },
      // {
      //   path: paths.subscribe,
      //   exact: true,
      //   component: withAnalytics(Subscribe),
      //   type: "subscribe",
      // },
      {
        path: paths.account,
        exact: true,
        component: withAnalytics(Account),
        type: "account",
      },
      {
        path: paths.forgotten_password,
        exact: true,
        component: withAnalytics(ForgottenPassword),
        type: "forgotten password",
      },
      {
        path: paths.reset_password,
        exact: true,
        component: withAnalytics(ResetPassword),
        type: "reset password",
      },
      {
        path: paths.power_search,
        exact: true,
        component: withAnalytics(PowerSearch),
        type: "power search",
      },
      {
        path: paths.all_jobs,
        exact: true,
        component: withAnalytics(ShowAllJobsContainer),
        type: "all jobs",
      },
      {
        path: paths.developer_jobs,
        exact: true,
        component: withAnalytics(ShowDevJobsContainer),
        type: "developer jobs",
      },
      {
        path: paths.marketing_jobs,
        exact: true,
        component: withAnalytics(ShowMarketingJobsContainer),
        type: "marketing jobs",
      },
      {
        path: paths.recruiter_jobs,
        exact: true,
        component: withAnalytics(ShowRecruiterJobsContainer),
        type: "recruiter jobs",
      },
      {
        path: paths.hr_jobs,
        exact: true,
        component: withAnalytics(ShowHRJobsContainer),
        type: "hr jobs",
      },
      {
        path: paths.sales_jobs,
        exact: true,
        component: withAnalytics(ShowSalesJobsContainer),
        type: "sales jobs",
      },
      {
        path: paths.social_media_jobs,
        exact: true,
        component: withAnalytics(ShowSocialMediaJobsContainer),
        type: "social media jobs",
      },
      {
        path: paths.seo_jobs,
        exact: true,
        component: withAnalytics(ShowSeoJobsContainer),
        type: "seo jobs",
      },
      {
        path: paths.manager_jobs,
        exact: true,
        component: withAnalytics(ShowManagerJobsContainer),
        type: "business jobs",
      },
      {
        path: paths.design_jobs,
        exact: true,
        component: withAnalytics(ShowDesignJobsContainer),
        type: "design jobs",
      },
      {
        path: paths.writing_jobs,
        exact: true,
        component: withAnalytics(ShowWritingJobsContainer),
        type: "writing jobs",
      },
      {
        path: paths.editing_jobs,
        exact: true,
        component: withAnalytics(ShowEditingJobsContainer),
        type: "editing jobs",
      },
      {
        path: paths.copywriter_jobs,
        exact: true,
        component: withAnalytics(ShowCopywriterJobsContainer),
        type: "copywriter jobs",
      },
      {
        path: paths.customer_service_jobs,
        exact: true,
        component: withAnalytics(ShowCustomerServiceJobsContainer),
        type: "customer service jobs",
      },
      {
        path: paths.devops_sysadmin_jobs,
        exact: true,
        component: withAnalytics(ShowDevopsSysAdminJobsContainer),
        type: "devops sysadmin jobs",
      },
      {
        path: paths.project_manager_jobs,
        exact: true,
        component: withAnalytics(ShowProjectManagerJobsContainer),
        type: "project manager jobs",
      },
      ...generateDeveloperRoutes(),
      ...generateDynamicLocationRoutes(),
      {
        path: paths.post_job,
        exact: true,
        component: withAnalytics(NewJob),
        type: "new job",
      },
      {
        path: `${paths.developer_jobs}/:id`,
        exact: false,
        component: withAnalytics(ShowSingleDevJobContainer),
        type: "developer job",
      },
      {
        path: `${paths.marketing_jobs}/:id`,
        exact: false,
        component: withAnalytics(ShowSingleMarketingJobContainer),
        type: "marketing job",
      },
      {
        path: `${paths.manager_jobs}/:id`,
        exact: false,
        component: withAnalytics(ShowSingleManagerJobContainer),
        type: "business job",
      },
      {
        path: `${paths.design_jobs}/:id`,
        exact: false,
        component: withAnalytics(ShowSingleDesignJobContainer),
        type: "design job",
      },
      {
        path: `${paths.writing_jobs}/:id`,
        exact: false,
        component: withAnalytics(ShowSingleWritingJobContainer),
        type: "writing job",
      },
      {
        path: `${paths.customer_service_jobs}/:id`,
        exact: false,
        component: withAnalytics(ShowSingleCustomerServiceJobContainer),
        type: "customer service job",
      },
      {
        path: `${paths.devops_sysadmin_jobs}/:id`,
        exact: false,
        component: withAnalytics(ShowSingleDevopsSysAdminJobContainer),
        type: "devops sysadmin job",
      },
      {
        path: paths.favourite_jobs,
        exact: true,
        component: withAnalytics(Favourites),
        type: "favourites",
      },
      {
        path: paths.thank_you,
        exact: true,
        component: withAnalytics(ThankYou),
        type: "job posted thank you",
      },
      {
        path: paths.about,
        exact: true,
        component: withAnalytics(About),
        type: "about",
      },
      {
        path: paths.contact,
        exact: true,
        component: withAnalytics(Contact),
        type: "contact",
      },
      {
        path: paths.partners,
        exact: true,
        component: withAnalytics(Partners),
        type: "partners",
      },
      {
        path: paths.terms,
        exact: true,
        component: withAnalytics(Terms),
        type: "terms and conditions",
      },
      {
        path: paths.privacy,
        exact: true,
        component: withAnalytics(Privacy),
        type: "privacy policy",
      },
      {
        path: paths.credits,
        exact: true,
        component: withAnalytics(Credits),
        type: "illustration credits",
      },
      {
        path: paths.subscribed,
        exact: true,
        component: withAnalytics(Subscribed),
        type: "subscribed email thank you",
      },
      {
        path: paths.resources,
        exact: true,
        component: withAnalytics(Resources),
        type: "resources",
      },
      {
        path: `${paths.resources}/:id`,
        exact: false,
        component: withAnalytics(Resource),
        type: "resource",
      },
      {
        path: `${paths.why_list}`,
        exact: true,
        component: withAnalytics(Listing),
        type: "why list with us",
      },
      {
        path: `${paths.companies}`,
        exact: true,
        component: withAnalytics(Companies),
        type: "companies",
      },
      {
        path: `${paths.companies}/:id`,
        exact: false,
        component: withAnalytics(Company),
        type: "company",
      },
      {
        path: `${paths.people}/:id`,
        exact: false,
        component: withAnalytics(Person),
        type: "person",
      },
      {
        path: `${paths.articles}/:id`,
        exact: false,
        component: withAnalytics(Article),
        type: "article",
      },
      {
        path: `${paths.resume_skills}`,
        exact: false,
        component: withAnalytics(Article),
        type: "skills for resume",
      },
      {
        path: `${paths.landing}/remote-jobs`,
        exact: true,
        component: withAnalytics(Landing),
        type: "landing page",
      },
      {
        path: `${paths.landing}/thanks`,
        exact: true,
        component: withAnalytics(LandingThanks),
        type: "landing page thanks",
      },
      {
        path: "*",
        exact: false,
        component: withAnalytics(ErrorPage),
        type: "page not found",
      },
    ],
  },
];

export default Routes;
