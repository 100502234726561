export const paymentMethodsActions = {
  FETCH_PAYMENT_METHODS_SUCCESS: `FETCH_PAYMENT_METHODS_SUCCESS`,
  FETCH_PAYMENT_METHODS: `FETCH_PAYMENT_METHODS`,
  FETCH_PAYMENT_METHODS_ERROR: `FETCH_PAYMENT_METHODS_ERROR`,
};

export const fetchingPaymentMethods = () => ({
  type: paymentMethodsActions.FETCH_PAYMENT_METHODS,
});

export const fetchPaymentMethodsSuccess = (methods) => ({
  type: paymentMethodsActions.FETCH_PAYMENT_METHODS_SUCCESS,
  payload: methods,
});

export const fetchPaymentMethodsError = (errorMessage) => ({
  type: paymentMethodsActions.FETCH_PAYMENT_METHODS_ERROR,
  payload: {
    message: errorMessage,
  },
});
