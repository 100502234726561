export const singleJobActions = {
  FETCH_SINGLE_JOB: `FETCH_SINGLE_JOB`,
  FETCH_SINGLE_JOB_SUCCESS: `FETCH_SINGLE_JOB_SUCCESS`,
  FETCH_SINGLE_JOB_ERROR: `FETCH_SINGLE_JOB_ERROR`,
  RESET_SINGLE_JOB: "RESET_SINGLE_JOB"
};

export const fetchingSingleJob = () => ({
  type: singleJobActions.FETCH_SINGLE_JOB
});

export const fetchSingleJobSuccess = job => ({
  type: singleJobActions.FETCH_SINGLE_JOB_SUCCESS,
  payload: job
});

export const fetchSingleJobError = errorMessage => ({
  type: singleJobActions.FETCH_SINGLE_JOB_ERROR
});

export const resetSingleJob = () => ({
  type: singleJobActions.RESET_SINGLE_JOB
});
