import React from "react";
import styled from "styled-components";
import { APPEND_ID } from "../../../../constants/ad-constants";
import { media } from "../../../../constants/theme";
import { appendAdScript } from "../../../../utils/ad-helpers";
import { useState, useEffect } from "react";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  #carbonads {
    display: flex;
    max-width: 500px;
    background-color: hsl(0, 0%, 100%);
    box-shadow: 0 1px 4px 1px hsla(0, 0%, 0%, 0.1);
    border-radius: 4px;
  }

  #carbonads a {
    text-decoration: none;
  }

  #carbonads span {
    position: relative;
    display: block;
    overflow: hidden;
  }

  #carbonads .carbon-wrap {
    display: flex;
    align-items: center;
  }

  .carbon-img {
    display: block;
    margin: 0;
    line-height: 1;
  }

  .carbon-img img {
    display: block;
    border-radius: 4px 0 0 4px;
  }

  .carbon-text {
    padding: 10px;
    text-align: left;
    font-size: 13px;
    line-height: 1.5;
    color: inherit;
  }

  .carbon-poweredby {
    position: relative;
    bottom: 0;
    right: 0;
    display: block;
    padding: 8px 10px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
    font-size: 9px;
    line-height: 1;
    background: repeating-linear-gradient(
        -45deg,
        transparent,
        transparent 5px,
        hsla(0, 0%, 0%, 0.025) 5px,
        hsla(0, 0%, 0%, 0.025) 10px
      )
      hsla(203, 11%, 95%, 0.4);
  }

  ${media.desktop`
  .carbon-poweredby {
    position: absolute;
    bottom: 0;
    right: 0;
    background: none;
  }
`}

  a.carbon-poweredby {
    color: #aaa;
  }
`;

export const JobListingsAdMobile = () => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 991) {
      setShouldRender(true);
    }
  }, []);

  useEffect(() => {
    if (shouldRender) appendAdScript();
  }, [shouldRender]);

  if (!shouldRender) return null;

  return <Wrapper id={APPEND_ID} />;
};
