import React from "react";
import Routes from "./routes";
import thunk from "redux-thunk";
import { hydrate } from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { Provider } from "react-redux";
import { rootReducer } from "./store/root-reducer";
import { renderRoutes } from "react-router-config";
import { lsMiddleware } from "./utils/localstorage";
import { loadableReady } from "@loadable/component";
const BrowserRouter = require("react-router-dom").BrowserRouter;

const preloadedState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

const middlewares = [thunk, lsMiddleware()];
const middleware = composeWithDevTools(applyMiddleware(...middlewares));

export const store = createStore(rootReducer, preloadedState, middleware);

loadableReady(() => {
  hydrate(
    <Provider store={store}>
      <BrowserRouter>{renderRoutes(Routes)}</BrowserRouter>
    </Provider>,
    document.getElementById("root")
  );
});

if (module.hot) {
  module.hot.accept();
}
