import { defaultMetaState } from "./meta-state";

export const defaultCompaniesEntityState = {
  companies: []
};

export const defaultCompaniesState = {
  entity: defaultCompaniesEntityState,
  meta: defaultMetaState
};
