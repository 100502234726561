import dynamicLocationJobs from "../app/pages/dynamic-location-jobs";
import { locations } from "../constants/dynamic-location-routes";
import { withAnalytics } from "./analytics";

export const generateDynamicLocationRoutes = () => {
  return locations.map((location) => {
    return {
      path: location.path,
      exact: true,
      component: withAnalytics(dynamicLocationJobs),
      type: `jobs in ${location.nameUppercase}`,
      data: {
        nameUppercase: location.nameUppercase,
        filterCategory: location.filterCategory,
        filterJobs: location.filterJobs,
      },
    };
  });
};

export const generateDynamicLocationFilters = () => {
  return locations.map((location) => {
    return {
      [location.filterCategory]: {
        filtersApplied: false,
        filters: {},
      },
    };
  });
};

export const countryFilter = (job, country) => {
  // No location restrictions
  if (job.location_restrictions.length === 0) {
    return true;
  }

  // Check if named country included in array of strings eg. ["Canada", "United States"]
  return job.location_restrictions.some((location) => location === country);
};
