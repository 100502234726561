import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { paths } from "../../../constants/paths";
import { media, maxWrapperWidth } from "../../../constants/theme";
import { PARTNER_PATHS } from "../../../constants/partner-paths";

const FooterWrapper = styled.footer`
  background-color: #1b1b1b;
  padding: 50px 15px;

  ul {
    list-style: none;
  }

  a {
    color: #fff;
    &:visited {
      color: #fff;
    }
  }

  ${media.desktop`
    padding: 50px 30px;
  `}
`;

const FooterLower = styled.div`
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  color: #ddd;
  font-weight: 300;
`;

const LinkList = styled.ul``;

const Li = styled.li`
  margin-bottom: 16px;
  a {
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: 300;
  }

  ${media.desktop`
    a {
      font-size: 16px;
    }
  `}
`;

const LinkHeading = styled.h4`
  color: #fff;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 18px;

  ${media.desktop`
    font-size: 16px;
  `}
`;

const LinkBlock = styled.div`
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.tablet`
    margin-right: 60px;

    &:last-child {
      margin-right: 0;
    }
  `}

  ${media.desktop`
    margin-right: 100px;

    &:last-child {
      margin-right: 0;
    }
  `}
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto 30px;
  max-width: ${maxWrapperWidth};

  ${media.desktop`
    flex-direction: row;
  `}
`;

const SocialBlock = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 25px;
  color: #fff;
  padding-top: 25px;
`;

const IconBlock = styled.div`
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  i {
    font-size: 20px;
  }
`;

const SocialLink = styled.a`
  width: 20px;
`;

// Comment commit

export const Footer = () => {
  return (
    <FooterWrapper>
      <LinksWrapper>
        <LinkBlock>
          <LinkHeading>Remote Jobs</LinkHeading>
          <LinkList>
            <Li>
              <Link to={paths.customer_service_jobs}>
                Remote Customer Service Jobs
              </Link>
            </Li>

            <Li>
              <Link to={paths.copywriter_jobs}>Remote Copywriter Jobs</Link>
            </Li>
            <Li>
              <Link to={paths.design_jobs}>Remote Design Jobs</Link>
            </Li>
            <Li>
              <Link to={paths.developer_jobs}>Remote Developer Jobs</Link>
            </Li>
            <Li>
              <Link to={paths.devops_sysadmin_jobs}>
                Remote Devops and Sysadmin Jobs
              </Link>
            </Li>
            <Li>
              <Link to={paths.editing_jobs}>Remote Editing Jobs</Link>
            </Li>
            <Li>
              <Link to={paths.hr_jobs}>Remote HR Jobs</Link>
            </Li>
            <Li>
              <Link to={paths.manager_jobs}>Remote Manager/Exec Jobs</Link>
            </Li>
            <Li>
              <Link to={paths.marketing_jobs}>Remote Marketing Jobs</Link>
            </Li>
            <Li>
              <Link to={paths.recruiter_jobs}>Remote Recruiter Jobs</Link>
            </Li>
            <Li>
              <Link to={paths.sales_jobs}>Remote Sales Jobs</Link>
            </Li>
            <Li>
              <Link to={paths.seo_jobs}>Remote SEO Jobs</Link>
            </Li>
            <Li>
              <Link to={paths.social_media_jobs}>Remote Social Media Jobs</Link>
            </Li>
            <Li>
              <Link to={paths.writing_jobs}>Remote Writing Jobs</Link>
            </Li>
          </LinkList>
        </LinkBlock>
        <LinkBlock>
          <LinkHeading>Our Partners</LinkHeading>
          <LinkList>
            <Li>
              <Link to={paths.companies}>Remote Companies</Link>
            </Li>
            <Li>
              <Link to={paths.credits}>Illustration Credits</Link>
            </Li>
            <Li>
              <a href={PARTNER_PATHS.WORKSTER} target="_blank">
                Remote jobs USA
              </a>
            </Li>
          </LinkList>
        </LinkBlock>
        <LinkBlock>
          <LinkHeading>More from us</LinkHeading>
          <LinkList>
            <Li>
              <Link to={paths.about}>About</Link>
            </Li>
            <Li>
              <Link to={paths.why_list}>Why list jobs with us?</Link>
            </Li>
            <Li>
              <Link to={paths.terms}>Terms & Conditions</Link>
            </Li>
            <Li>
              <Link to={paths.privacy}>Privacy</Link>
            </Li>
            <Li>
              <Link to={paths.contact}>Contact</Link>
            </Li>
          </LinkList>
        </LinkBlock>
        <LinkBlock>
          <LinkHeading>Resources</LinkHeading>
          <LinkList>
            <Li>
              <Link to={`${paths.resume_skills}`}>Skills For Resume</Link>
            </Li>
            <Li>
              <Link
                to={`${paths.articles}/top-5-remote-work-habits-to-boost-your-productivity`}
              >
                Top 5 Remote Work Habits to Boost your Productivity
              </Link>
            </Li>
            <Li>
              <Link to={`${paths.articles}/work-from-home-jobs`}>
                Work From Home Jobs in 2023
              </Link>
            </Li>
            <Li>
              <Link
                to={`${paths.articles}/working-remotely-into-the-evenings-with-a-laptop-lamp-that-doesnt-glare`}
              >
                Working Remotely into the evenings with a laptop lamp that
                doesn't glare
              </Link>
            </Li>
            <Li>
              <Link
                to={`${paths.articles}/10-remote-work-statistics-to-know-in-2021`}
              >
                10 Remote Work Statistics to Know in 2021
              </Link>
            </Li>
            <Li>
              <Link
                to={`${paths.articles}/how-working-remotely-is-changing-the-workforce-for-the-better`}
              >
                How Working Remotely is Changing the Workforce for the Better
              </Link>
            </Li>
            <Li>
              <Link to={`${paths.articles}/meet-mike-from-hobo-with-a-laptop`}>
                Meet Mike from Hobo with a Laptop
              </Link>
            </Li>
            <Li>
              <Link
                to={`${paths.articles}/design-and-development-skills-for-remote-working`}
              >
                Design and Development Skills For Remote Working
              </Link>
            </Li>
            <Li>
              <Link to={`${paths.articles}/how-to-work-from-home`}>
                How to Work from Home
              </Link>
            </Li>
            <Li>
              <Link
                to={`${paths.articles}/how-to-secure-your-first-remote-job`}
              >
                How to Secure your first Remote Job
              </Link>
            </Li>
            <Li>
              <Link
                to={`${paths.articles}/building-a-better-platform-for-remote-workers`}
              >
                Building a Better Platform for Remote Workers
              </Link>
            </Li>
          </LinkList>
        </LinkBlock>
      </LinksWrapper>

      <SocialBlock>
        <IconBlock>
          <SocialLink
            href="https://instagram.com/justremoteco"
            target="_blank"
            aria-label="Instagram"
            rel="noopener noreferrer"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
              ></path>
            </svg>
          </SocialLink>
          <SocialLink
            href="https://twitter.com/justremoteco"
            target="_blank"
            aria-label="Twitter"
            rel="noopener noreferrer"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
              ></path>
            </svg>
          </SocialLink>
          <SocialLink
            href="https://facebook.com/justremoteco"
            target="_blank"
            aria-label="Facebook"
            rel="noopener noreferrer"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
              ></path>
            </svg>
          </SocialLink>
          <SocialLink
            href="https://medium.com/justremote"
            target="_blank"
            aria-label="Medium"
            rel="noopener noreferrer"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M0 32v448h448V32H0zm372.2 106.1l-24 23c-2.1 1.6-3.1 4.2-2.7 6.7v169.3c-.4 2.6.6 5.2 2.7 6.7l23.5 23v5.1h-118V367l24.3-23.6c2.4-2.4 2.4-3.1 2.4-6.7V199.8l-67.6 171.6h-9.1L125 199.8v115c-.7 4.8 1 9.7 4.4 13.2l31.6 38.3v5.1H71.2v-5.1l31.6-38.3c3.4-3.5 4.9-8.4 4.1-13.2v-133c.4-3.7-1-7.3-3.8-9.8L75 138.1V133h87.3l67.4 148L289 133.1h83.2v5z"
              ></path>
            </svg>
          </SocialLink>
        </IconBlock>
      </SocialBlock>

      <FooterLower>Copyright &copy; Superbrnds Ltd 2023</FooterLower>
    </FooterWrapper>
  );
};
