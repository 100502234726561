import React, { Component } from "react";
import styled from "styled-components";
import { setCookie, getCookie } from "../../../utils/cookies";
import { COOKIE_NAMES } from "../../../constants/cookies";
import { media, colors } from "../../../constants/theme";
import { CloseIcon } from "../../atoms/close-icon";

const FormWrapper = styled.div`
  position: fixed;
  bottom: ${(props) => (props.open ? "20px" : "-480px")};
  right: 20px;
  left: 20px;
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  padding: 30px;
  border-left: 5px solid ${colors.primaryRed};
  z-index: 8;
  transition: 0.2s ease-in;

  ${media.tablet`
    padding: 60px;
    left: initial;
    max-width: 500px;
  `}
`;

const CloseForm = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 24px;
`;

const EmailInput = styled.input`
  font-weight: 300;
  appearance: none;
  padding: 10px;
  font-size: 20px;
  border: 1px solid #ddd;
  width: 100%;
  outline: none;
  border-radius: 3px;
  margin-bottom: 15px;
`;

const CheckboxWrapper = styled.div`
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    margin-right: 5px;
  }

  label {
    user-select: none;
  }
`;
const Checkboxes = styled.div`
  margin-bottom: 20px;
`;
const FormTitle = styled.h3`
  font-size: 24px;
`;
const Button = styled.button`
  width: 100%;
  background-color: ${colors.primaryRed};
  color: ${colors.white};
  user-select: none;
  font-style: normal;
  font-size: 16px;
  font-family: "europa", sans-serif;
  padding: 15px 35px;
  border-radius: 4px;
  font-weight: 600;
  border: 0;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export class EmailForm extends Component {
  componentDidMount() {
    const dismissedForm = getCookie(COOKIE_NAMES.EMAIL_FORM_DISMISSED);
    const submittedForm = getCookie(COOKIE_NAMES.EMAIL_FORM_SUBMITTED);
    const isLandingPage = window.location.href.indexOf("/l/") > 0;

    if (
      dismissedForm === undefined &&
      submittedForm === undefined &&
      isLandingPage === false &&
      window.innerWidth > 768
    ) {
      setTimeout(() => {
        this.props.showEmailForm();
      }, 2000);
    }
  }

  handleSubmit = () => {
    setCookie(COOKIE_NAMES.EMAIL_FORM_SUBMITTED, null, 730);
  };

  handleClose = () => {
    this.props.hideEmailForm();
    setCookie(COOKIE_NAMES.EMAIL_FORM_DISMISSED, null, 3);
  };

  render() {
    const { open } = this.props;
    return (
      <div>
        <FormWrapper open={open}>
          <CloseForm>
            <CloseIcon onClick={this.handleClose} />
          </CloseForm>
          <FormTitle>Remote Jobs Weekly</FormTitle>
          <p>
            Just the remote jobs you want sent straight to your inbox weekly.
          </p>
          <form
            id="subForm"
            className="js-cm-form"
            onSubmit={this.handleSubmit}
            action="https://www.createsend.com/t/subscribeerror?description="
            method="post"
            data-id="A61C50BEC994754B1D79C5819EC1255C95D0A7813FA92D5C40C5B5E2E1B382566514DE71FF4F536F97045CBD61EEA830EB46E6B5631922E47763E51DCA3FC99A"
          >
            <EmailInput
              id="fieldEmail"
              placeholder="Email"
              name="cm-jyhkhiu-jyhkhiu"
              type="email"
              className="js-cm-email-input qa-input-email"
              aria-label="Email"
              maxlength="200"
              required
            />

            <Checkboxes>
              <CheckboxWrapper>
                <input
                  id="5243329"
                  name="cm-fo-thtuhid"
                  value="5243329"
                  type="checkbox"
                />
                <label htmlFor="5243329">Developer Jobs</label>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <input
                  id="5243326"
                  name="cm-fo-thtuhid"
                  value="5243326"
                  type="checkbox"
                />
                <label htmlFor="5243326">Marketing Jobs</label>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <input
                  id="5243328"
                  name="cm-fo-thtuhid"
                  value="5243328"
                  type="checkbox"
                />
                <label htmlFor="5243328">Business/Exec Jobs</label>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <input
                  id="5243327"
                  name="cm-fo-thtuhid"
                  value="5243327"
                  type="checkbox"
                />
                <label htmlFor="5243327">Design Jobs</label>
              </CheckboxWrapper>
            </Checkboxes>

            <Button className="js-cm-submit-button" type="submit">
              Get Weekly Jobs
            </Button>
          </form>
        </FormWrapper>
      </div>
    );
  }
}
