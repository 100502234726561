export const subscriptionActions = {
  FETCH_SUBSCRIPTION_SUCCESS: `FETCH_SUBSCRIPTION_SUCCESS`,
  FETCH_SUBSCRIPTION: `FETCH_SUBSCRIPTION`,
  FETCH_SUBSCRIPTION_ERROR: `FETCH_SUBSCRIPTION_ERROR`,
};

export const fetchingSubscription = () => ({
  type: subscriptionActions.FETCH_SUBSCRIPTION,
});

export const fetchSubscriptionSuccess = (subscription) => ({
  type: subscriptionActions.FETCH_SUBSCRIPTION_SUCCESS,
  payload: subscription,
});

export const fetchSubscriptionError = (errorMessage) => ({
  type: subscriptionActions.FETCH_SUBSCRIPTION_ERROR,
  payload: {
    message: errorMessage,
  },
});
