import React, { Component } from "react";
import styled from "styled-components";
import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";
import { paths } from "../../../constants/paths";
import { withRouter } from "react-router-dom";
import { media } from "../../../constants/theme";
import { TrackClick } from "../../../utils/analytics";
import { CloseIcon } from "../../atoms/close-icon";
import { MenuBars } from "../../atoms/menu-bars";

const DrawContainer = styled.div`
  ${media.desktop`
    display:none;
  `}
`;

const Bars = styled.div`
  &:hover {
    cursor: pointer;
  }
`;
const DrawInner = styled.div`
  padding: 20px;
`;

const Close = styled.div`
  text-align: right;
  font-size: 30px;
  &:hover {
    cursor: pointer;
  }
`;
const MenuList = styled.ul`
  margin-bottom: 30px;
  list-style: none;

  li {
    font-style: italic;
    font-size: ${(props) => (props.small ? "18px" : "26px")};
    font-weight: 600;
    margin-bottom: 10px;

    &:hover {
      cursor: pointer;
    }
  }
`;

const Icon = styled.div`
  display: block;
  pointer: cursor;
  position: relative;
  top: 1px;
  font-size: 18px;
`;

const styles = {
  paper: {
    minWidth: "85%",
  },
};

class WrappedMenuDraw extends Component {
  constructor() {
    super();

    this.state = {
      open: false,
    };

    this.handleRoute = this.handleRoute.bind(this);
  }

  handleRoute(path) {
    this.setState(
      {
        open: false,
      },
      () => {
        this.props.history.push(path);
      }
    );
  }

  render() {
    const classes = this.props.classes;
    return (
      <DrawContainer>
        <Drawer
          onClose={() => this.setState({ open: false })}
          open={this.state.open}
          classes={{
            paper: classes.paper,
          }}
        >
          <DrawInner>
            <Close onClick={() => this.setState({ open: false })}>
              <CloseIcon height="30" />
            </Close>
            <MenuList>
              <li
                onClick={() => {
                  TrackClick({
                    category: "Navigation - Menu",
                    action: "Link Clicked",
                    label: "Customer Service",
                  });
                  this.handleRoute(paths.customer_service_jobs);
                }}
              >
                Customer Service Jobs
              </li>
              <li
                onClick={() => {
                  TrackClick({
                    category: "Navigation - Menu",
                    action: "Link Clicked",
                    label: "Developer",
                  });
                  this.handleRoute(paths.developer_jobs);
                }}
              >
                Developer Jobs
              </li>
              <li
                onClick={() => {
                  TrackClick({
                    category: "Navigation - Menu",
                    action: "Link Clicked",
                    label: "Devops & Sysadmin",
                  });
                  this.handleRoute(paths.devops_sysadmin_jobs);
                }}
              >
                Devops & Sysadmin Jobs
              </li>

              <li
                onClick={() => {
                  TrackClick({
                    category: "Navigation - Menu",
                    action: "Link Clicked",
                    label: "Marketing",
                  });
                  this.handleRoute(paths.marketing_jobs);
                }}
              >
                Marketing Jobs
              </li>
              <li
                onClick={() => {
                  TrackClick({
                    category: "Navigation - Menu",
                    action: "Link Clicked",
                    label: "Design",
                  });
                  this.handleRoute(paths.design_jobs);
                }}
              >
                Design Jobs
              </li>
              <li
                onClick={() => {
                  TrackClick({
                    category: "Navigation - Menu",
                    action: "Link Clicked",
                    label: "Manager",
                  });
                  this.handleRoute(paths.manager_jobs);
                }}
              >
                Manager/Exec Jobs
              </li>
              <li
                onClick={() => {
                  TrackClick({
                    category: "Navigation - Menu",
                    action: "Link Clicked",
                    label: "Writing",
                  });
                  this.handleRoute(paths.writing_jobs);
                }}
              >
                Writing Jobs
              </li>
            </MenuList>
            <MenuList>
              <li
                onClick={() => {
                  TrackClick({
                    category: "Navigation - Menu",
                    action: "Link Clicked",
                    label: "Post Job",
                  });
                  this.handleRoute(paths.post_job);
                }}
              >
                Post a Job
              </li>
            </MenuList>
            <MenuList small>
              <li
                onClick={() => {
                  TrackClick({
                    category: "Navigation - Menu",
                    action: "Link Clicked",
                    label: "About",
                  });
                  this.handleRoute(paths.about);
                }}
              >
                About
              </li>
              <li
                onClick={() => {
                  TrackClick({
                    category: "Navigation - Menu",
                    action: "Link Clicked",
                    label: "Why list with us",
                  });
                  this.handleRoute(paths.why_list);
                }}
              >
                Why list with us
              </li>
              <li
                onClick={() => {
                  TrackClick({
                    category: "Navigation - Menu",
                    action: "Link Clicked",
                    label: "Contact",
                  });
                  this.handleRoute(paths.contact);
                }}
              >
                Contact us
              </li>
            </MenuList>
          </DrawInner>
        </Drawer>
        <Bars>
          <Icon onClick={() => this.setState({ open: true })}>
            <MenuBars />
          </Icon>
        </Bars>
      </DrawContainer>
    );
  }
}
export const MenuDraw = withRouter(withStyles(styles)(WrappedMenuDraw));
