import React from "react";
import styled from "styled-components";
import { TrackClick } from "../../../../utils/analytics";

const Wrapper = styled.div`
  margin-top: 24px;
`;

const StyledLink = styled.a`
  span {
    text-decoration: underline;
  }
`;

export const WorksterAd = () => {
  return (
    <Wrapper>
      <StyledLink
        href="https://workster.co?ref=justremote"
        target="_blank"
        onClick={() => {
          TrackClick({
            category: "Workster",
            action: "Workster - Job Listings - Sidebar",
            label: "Workster - Job Listings",
          });
        }}
      >
        🇺🇸 Are you a US resident looking for a remote job? Take a look at{" "}
        <span>Workster!</span> 👀
      </StyledLink>
    </Wrapper>
  );
};
