export const getCookie = name => {
  let c = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  if(c) return c[2];
  return;
}

export const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  return;
}

export const deleteCookie = name => {  
  return document.cookie = `${name}=; Max-Age=-99999999; path=/`;
}