import React, { Component } from "react";
import GA from "react-ga";

GA.initialize("UA-120527102-1"); // Add { debug: true } as second arg for logging

export const withTracker = (WrappedComponent) => {
  const trackPage = (page) => {
    GA.pageview(page);
  };

  const HOC = class extends Component {
    static fetchData(store, url) {
      // Ensure to Fetch data for SSR!
      return WrappedComponent.fetchData instanceof Function
        ? WrappedComponent.fetchData(store, url)
        : Promise.resolve(null);
    }

    componentDidMount() {
      const page = this.props.location.pathname;
      trackPage(page);
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export const TrackClick = (props) => {
  const { category, action, label } = props;
  let obj = {
    category: category,
    action: action,
  };

  if (label) obj.label = label;
  GA.event(obj);
};

export const withAnalytics = (component) => {
  if (process.env.NODE_ENV === "production") {
    return withTracker(component);
  }
  return component;
};
