import { defaultSingleJobState } from "../state/single-job-state";
import { singleJobActions } from "../actions/single-job-actions";

const handlers = {
  [singleJobActions.FETCH_SINGLE_JOB_ERROR]: (state, payload) => {
    return Object.assign({}, state, {
      error: payload
    });
  },

  [singleJobActions.FETCH_SINGLE_JOB_SUCCESS]: (state, payload) => {
    let category = payload.category;
    return Object.assign({}, state, {
      [category]: payload
    });
  },

  [singleJobActions.RESET_SINGLE_JOB]: (state, payload) => {
    return Object.assign({}, state, defaultSingleJobState);
  }
};

const singleJobReducer = (state = defaultSingleJobState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default singleJobReducer;
