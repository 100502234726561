import React from 'react';
import styled from 'styled-components';

const StyledErrorMessage = styled.p`
  margin-top: 5px;
  color: red;
  font-size: 14px;
`

export const ErrorMessage = props => {

  return(
    <StyledErrorMessage className="error-message">{ props.message ? props.message : null }</StyledErrorMessage>
  )
}