import React from "react";
import styled from "styled-components";
import { useEffect } from "react";
import { APPEND_ID } from "../../../../constants/ad-constants";
import { appendAdScript } from "../../../../utils/ad-helpers";

const Wrapper = styled.div`
  #carbonads {
    display: flex;
    background-color: hsl(0, 0%, 100%);
    box-shadow: 0 1px 4px 1px hsla(0, 0%, 0%, 0.1);
    border-radius: 4px;
  }

  #carbonads a {
    text-decoration: none;
  }

  #carbonads a:hover {
  }

  #carbonads span {
    position: relative;
    display: block;
    overflow: hidden;
  }

  #carbonads .carbon-wrap {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1100px) {
      flex-direction: row;
    }
  }

  .carbon-img {
    display: flex;
    margin: 0;
    line-height: 1;
    justify-content: center;
    padding-top: 10px;
    @media screen and (min-width: 1100px) {
      flex-direction: row;
      padding-top: 0;
    }
  }

  .carbon-text {
    padding: 10px;
    text-align: left;
    font-size: 13px;
    line-height: 1.5;
    color: inherit;
  }

  .carbon-poweredby {
    color: #aaa;
    position: relative;
    bottom: 0;
    right: 0;
    display: block;
    padding: 8px 10px;
    background: repeating-linear-gradient(
        -45deg,
        transparent,
        transparent 5px,
        hsla(0, 0%, 0%, 0.025) 5px,
        hsla(0, 0%, 0%, 0.025) 10px
      )
      hsla(203, 11%, 95%, 0.4);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
    font-size: 9px;
    line-height: 1;
  }

  a.carbon-poweredby {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }
`;

export const JobListingsAdDesktop = () => {
  useEffect(() => {
    if (window.innerWidth >= 992) {
      appendAdScript();
    }
  }, []);

  return <Wrapper id={APPEND_ID} />;
};
