export const paymentIntentActions = {
  PAYMENT_INTENT: `PAYMENT_INTENT`,
  PAYMENT_INTENT_ERROR: `PAYMENT_INTENT_ERROR`,
  PAYMENT_INTENT_SUCCESS: `PAYMENT_INTENT_SUCCESS`
};

export const paymentIntent = () => ({
  type: paymentIntentActions.PAYMENT_INTENT
});

export const paymentIntentSuccess = secret => ({
  type: paymentIntentActions.PAYMENT_INTENT_SUCCESS,
  payload: secret
});

export const paymentIntentError = () => ({
  type: paymentIntentActions.PAYMENT_INTENT_ERROR
});
