import { paymentMethodsActions } from "../actions/payment-methods-actions";
import { metaStatuses, defaultMetaState } from "../state/meta-state";
import {
  defaultPaymentMethodsEntityState,
  defaultPaymentMethodsState,
} from "../state/payment-methods";

const handlers = {
  [paymentMethodsActions.FETCH_PAYMENT_METHODS]: (state) => {
    return Object.assign({}, state, {
      entity: defaultPaymentMethodsEntityState,
      meta: {
        ...defaultMetaState,
        status: metaStatuses.LOADING,
      },
    });
  },

  [paymentMethodsActions.FETCH_PAYMENT_METHODS_ERROR]: (state, payload) => {
    return Object.assign({}, state, {
      entity: defaultPaymentMethodsEntityState,
      meta: {
        status: metaStatuses.FAILURE,
        error: payload.message,
      },
    });
  },
  [paymentMethodsActions.FETCH_PAYMENT_METHODS_SUCCESS]: (state, payload) => {
    return Object.assign({}, state, {
      entity: {
        cards: payload,
      },
      meta: {
        ...defaultMetaState,
        status: metaStatuses.SUCCESS,
      },
    });
  },
};

const paymentMethodReducer = (state = defaultPaymentMethodsState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default paymentMethodReducer;
