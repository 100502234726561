import { defaultPersonState } from "../state/person-state";
import { personActions } from "../actions/person-actions";

const handlers = {
  [personActions.FETCH_PERSON_ERROR]: (state, payload) => {
    return Object.assign({}, state, {
      error: payload
    });
  },

  [personActions.FETCH_PERSON_SUCCESS]: (state, payload) => {
    const data = payload.data;
    return Object.assign({}, state, {
      uid: payload.uid,
      name: data.name,
      shortName: data.short_name,
      questionOne: data.question_one,
      questionTwo: data.question_two,
      questionThree: data.question_three,
      questionFour: data.question_four,
      questionFive: data.question_five,
      questionSix: data.question_six,
      questionSeven: data.question_seven,
      questionEight: data.question_eight,
      image: data.profile_image,
      website: data.website,
      remoteSince: data.remote_since,
      category: data.industry_category,
      instagram: data.instagram ? data.instagram : "",
      twitter: data.twitter ? data.twitter : "",
      facebook: data.facebook ? data.facebook : "",
      medium: data.medium ? data.medium : "",
      linkedin: data.linkedin ? data.linkedin : ""
    });
  }
};

const personReducer = (state = defaultPersonState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default personReducer;
