import React, { Component } from 'react';
import styled from 'styled-components';

const StyledFormField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`

export class FormField extends Component {
  render() {
    return (
      <StyledFormField>
        {this.props.children}
      </StyledFormField>
    )
  }
}
