import { defaultModalState } from "../state/modal-state";
import { modalActions } from "../actions/modal-actions";

const handlers = {
  [modalActions.OPEN_MODAL]: (state, payload) => {
    return Object.assign({}, state, {
      open: true,
      modalId: payload.modalId,
      data: payload.data || null,
    });
  },
  [modalActions.CLOSE_MODAL]: (state, payload) => {
    return Object.assign({}, state, {
      open: false,
      modalId: "",
      data: null,
    });
  },
};

const modalReducer = (state = defaultModalState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default modalReducer;
