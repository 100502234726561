import styled from "styled-components";
import { media } from "../../../constants/theme";

export const Filter = styled.div`
  display: block;
  padding: 0 14px;
  margin-bottom: 14px;
  ${media.desktop`
    margin-bottom: 32px;
    padding: 0;
  `}
`;
