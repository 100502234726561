export const articleActions = {
  FETCH_ARTICLE_SUCCESS: `FETCH_ARTICLE_SUCCESS`,
  FETCH_ARTICLE: `FETCH_ARTICLE`,
  FETCH_ARTICLE_ERROR: `FETCH_ARTICLE_ERROR`
};

export const fetchingArticle = () => ({
  type: articleActions.FETCH_ARTICLE
});

export const fetchArticleSuccess = article => ({  
  type: articleActions.FETCH_ARTICLE_SUCCESS,
  payload: article
});

export const fetchArticleError = errorMessage => ({
  type: articleActions.FETCH_ARTICLE_ERROR
});

