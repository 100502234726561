import { defaultMetaState } from "./meta-state";

export const defaultCompanyEntityState = {
  company: null
};

export const defaultCompanyState = {
  entity: defaultCompanyEntityState,
  meta: defaultMetaState
};
