export const skills = [
  "javascript",
  "python",
  "java",
  "kotlin",
  "ruby",
  "ruby-on-rails",
  "node",
  "ios",
  "swift",
  "android",
  "asp-net",
  "react",
  "angular",
  "vue",
  "php",
  "go",
  "aws",
  "typescript",
];
