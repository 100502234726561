import React from "react";
import styled from "styled-components";
import { ErrorMessage } from "../error-message";
import { colors } from "../../../constants/theme";

const StyledInput = styled.input`
  appearance: none;
  padding: 20px 15px;
  font-size: 16px;
  border: ${(props) => {
    if (props.new) {
      return "0px";
    }
    if (props.isInvalid) {
      return "1px solid red";
    }
    return "1px solid #ddd";
  }};
  width: 100%;
  font-weight: 400;
  outline: none;
  border-radius: 3px;
  background-color: ${(props) => (props.new ? colors.backgroundBlue : "white")};
`;

export const Input = (props) => {
  return (
    <div>
      <StyledInput
        name={props.name}
        new={props.new}
        isInvalid={props.validate ? props.validate.isInvalid : null}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.change}
        type={props.type ? props.type : null}
      />
      {props.validate && <ErrorMessage message={props.validate.message} />}
    </div>
  );
};
