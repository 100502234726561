export const loadingActions = {
  SHOW_LOADER: `SHOW_LOADER`,
  HIDE_LOADER: `HIDE_LOADER`
};

export const showLoader = () => ({
  type: loadingActions.SHOW_LOADER
});

export const hideLoader = () => ({
  type: loadingActions.HIDE_LOADER,
});
