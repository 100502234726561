import { connect } from 'react-redux';
import { EmailForm } from './emailForm';
import { showEmailForm, hideEmailForm } from '../../../store/actions/email-form-actions';
  
const mapStateToProps = state => {
  return {
    open: state.emailFormState.open
  }
};
  
const mapDispatchToProps = dispatch => {
  return {
    showEmailForm() {
      dispatch(showEmailForm())
    },
    hideEmailForm() {
      dispatch(hideEmailForm())
    }
  }
};
  
export const EmailFormContainer = connect(mapStateToProps, mapDispatchToProps)(EmailForm);