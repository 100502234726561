import { defaultMetaState } from "./meta-state";

export const defaultPaymentMethodsEntityState = {
  cards: [],
};

export const defaultPaymentMethodsState = {
  entity: defaultPaymentMethodsEntityState,
  meta: defaultMetaState,
};
