export const emailFormActions = {
  SHOW_EMAIL_FORM: `SHOW_EMAIL_FORM`,
  HIDE_EMAIL_FORM: `HIDE_EMAIL_FORM`
};

export const showEmailForm = () => ({
  type: emailFormActions.SHOW_EMAIL_FORM
});

export const hideEmailForm = () => ({
  type: emailFormActions.HIDE_EMAIL_FORM,
});
