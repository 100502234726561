import React from "react";
import styled from "styled-components";
import { paths } from "../../../constants/paths";
import { CategoryLink } from "../../atoms/category-link";

const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const JobCategories = ({ small }) => {
  return (
    <LinksWrapper>
      <CategoryLink isSmall={small} path={paths.all_jobs} title="All" />
      <CategoryLink
        isSmall={small}
        path={paths.manager_jobs}
        title="Business/Exec"
      />
      <CategoryLink
        isSmall={small}
        path={paths.customer_service_jobs}
        title="Customer Service"
      />
      <CategoryLink
        isSmall={small}
        path={paths.developer_jobs}
        title="Developer"
      />
      <CategoryLink
        isSmall={small}
        path={paths.devops_sysadmin_jobs}
        title="Devops & Sysadmin"
      />
      <CategoryLink isSmall={small} path={paths.design_jobs} title="Design" />
      <CategoryLink isSmall={small} path={paths.editing_jobs} title="Editing" />
      <CategoryLink
        isSmall={small}
        path={paths.marketing_jobs}
        title="Marketing"
      />
      <CategoryLink isSmall={small} path={paths.hr_jobs} title="HR" />
      <CategoryLink
        isSmall={small}
        path={paths.project_manager_jobs}
        title="Project Manager"
      />
      <CategoryLink
        isSmall={small}
        path={paths.recruiter_jobs}
        title="Recruiter"
      />
      <CategoryLink isSmall={small} path={paths.sales_jobs} title="Sales" />
      <CategoryLink isSmall={small} path={paths.seo_jobs} title="SEO" />
      <CategoryLink
        isSmall={small}
        path={paths.social_media_jobs}
        title="Social Media"
      />
      <CategoryLink isSmall={small} path={paths.writing_jobs} title="Writing" />
    </LinksWrapper>
  );
};
