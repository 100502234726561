import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setCategory } from "../../../utils/set-category";
import { metaStatuses } from "../../../store/state/meta-state";
import { filterJobs } from "../../../utils/filter";
import { fetchJobs } from "../../../store/thunks/jobs";
import { Helmet } from "react-helmet";
import { setFavourite } from "../../../store/actions/favourite-actions";
import {
  addFilter,
  removeFilter,
  clearFilters,
  removeFromFilters,
  addToFilters,
} from "../../../store/actions/filter-actions";
import { JobListings } from "../../components/job-listings";
import LazyLoad from "react-lazyload";
import { PopularCategories } from "../../components/popular-categories";
import { Footer } from "../../components/footer";
import { SmallHero } from "../../components/small-hero";

export class DynamicLocationJobs extends Component {
  static fetchData(store, url) {
    return store.dispatch(fetchJobs());
  }

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.jobsRef = React.createRef();
  }

  componentDidMount() {
    const { meta, category, fetchJobs, clearFilters } = this.props;

    window.scrollTo(0, 0);
    clearFilters(setCategory(category));
    if (meta.status === metaStatuses.DEFAULT) {
      fetchJobs();
    }
  }

  render() {
    const {
      path,
      data: { nameUppercase, filterCategory },
    } = this.props.route;
    const {
      jobs,
      filters,
      filtersApplied,
      addFilter,
      removeFilter,
      clearFilters,
      removeFromFilters,
      addToFilters,
      meta,
    } = this.props;

    const featuredJobs = jobs && jobs.filter((job) => job.is_featured === true);
    const filteredJobs = jobs && filterJobs(jobs, filters);

    return (
      <>
        <Helmet>
          <title>{`Remote Jobs in ${nameUppercase} - JustRemote`}</title>
          <meta
            name="description"
            content={`Remote Jobs in ${nameUppercase}. Every job listed is available to people living in ${nameUppercase}. Apply now to amazing remote jobs from the worlds best remote companies.`}
          />
          <link rel="canonical" href={path} />
        </Helmet>
        <div>
          <SmallHero
            title={`Remote Jobs in ${nameUppercase}`}
            subtitle={`Every job position listed below is accepting applicants who live in ${nameUppercase}`}
          />
          <div ref={this.jobsRef} />
          <JobListings
            featuredJobs={featuredJobs}
            meta={meta}
            category={filterCategory}
            filters={filters}
            filtersApplied={filtersApplied}
            filteredJobs={filteredJobs}
            addFilter={addFilter}
            removeFilter={removeFilter}
            clearFilters={clearFilters}
            jobs={jobs}
            removeFromFilters={removeFromFilters}
            addToFilters={addToFilters}
            noTopCategories
          />
          <LazyLoad offset={1000} height={466} once>
            <PopularCategories />
          </LazyLoad>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { filterCategory, filterJobs } = ownProps.route.data;
  return {
    meta: state.jobsState.meta,
    jobs: filterJobs(state.jobsState.entity.all),
    filters: state.filterState[filterCategory].filters,
    filtersApplied: state.filterState[filterCategory].filtersApplied,
    favourited: state.favouriteState.favourites,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchJobs: bindActionCreators(fetchJobs, dispatch),
    handleFavouriteClick(job) {
      dispatch(setFavourite(job));
    },
    addFilter(filter) {
      dispatch(addFilter(filter));
    },
    removeFilter(filter) {
      dispatch(removeFilter(filter));
    },
    clearFilters(category) {
      dispatch(clearFilters(category));
    },
    removeFromFilters(filter) {
      dispatch(removeFromFilters(filter));
    },
    addToFilters(filter) {
      dispatch(addToFilters(filter));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicLocationJobs);
