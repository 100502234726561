export const userActions = {
  FETCH_USER: `FETCH_USER`,
  FETCH_USER_SUCCESS: `FETCH_USER_SUCCESS`,
  FETCH_USER_ERROR: `FETCH_USER_ERROR`,
  RESET_USER: "RESET_USER",
};

export const fetchingUser = () => ({
  type: userActions.FETCH_USER,
});

export const fetchUserSuccess = (user) => ({
  type: userActions.FETCH_USER_SUCCESS,
  payload: user,
});

export const fetchUserError = () => ({
  type: userActions.FETCH_USER_ERROR,
});

export const resetUser = () => ({
  type: userActions.RESET_USER,
});
