export const BASE_API_VERSION = "/api/v1";

export const endpoints = {
  CREATE_USER: "/create_user",
  GET_USER: "/get_user",
  UPDATE_USER: "/update_user",
  LOGIN: "/login",
  GET_JOBS: "/jobs",
  SUBSCRIBE: "/subscribe",
  CANCEL: "/cancel",
  RESTART: "/restart",
  GET_PAYMENT_METHODS: "/payment_methods",
  GET_PLAN: "/plan",
  GET_SUBSCRIPTION: "/subscription",
  FORGOTTEN_PASSWORD: "/forgot_password",
  RESET_PASSWORD: "/reset_password",
  GET_JOBS_COUNT: "/jobs_count",
  UPDATE_PASSWORD: "/update_password",
  CREATE_LEAD: "/lead",
};
