export const jobsActions = {
  FETCH_ALL_JOBS_SUCCESS: `FETCH_ALL_JOBS_SUCCESS`,
  FETCH_JOBS: `FETCH_JOBS`,
  FETCH_JOBS_ERROR: `FETCH_JOBS_ERROR`
};

export const fetchingJobs = () => ({
  type: jobsActions.FETCH_JOBS
});

export const fetchAllJobsSuccess = jobs => ({
  type: jobsActions.FETCH_ALL_JOBS_SUCCESS,
  payload: jobs
});

export const fetchJobsError = errorMessage => ({
  type: jobsActions.FETCH_JOBS_ERROR,
  payload: {
    message: errorMessage
  }
});
