import { jobsActions } from "../actions/jobs-actions";
import { defaultJobsState, defaultJobEntityState } from "../state/jobs-state";
import { metaStatuses, defaultMetaState } from "../state/meta-state";

const handlers = {
  [jobsActions.FETCH_JOBS]: state => {
    return Object.assign({}, state, {
      entity: defaultJobEntityState,
      meta: {
        ...defaultMetaState,
        status: metaStatuses.LOADING
      }
    });
  },

  [jobsActions.FETCH_JOBS_ERROR]: (state, payload) => {
    return Object.assign({}, state, {
      entity: defaultJobEntityState,
      meta: {
        status: metaStatuses.FAILURE,
        error: payload.message
      }
    });
  },
  [jobsActions.FETCH_ALL_JOBS_SUCCESS]: (state, payload) => {
    return Object.assign({}, state, {
      entity: {
        all: payload
      },
      meta: {
        ...defaultMetaState,
        status: metaStatuses.SUCCESS
      }
    });
  }
};

const jobsReducer = (state = defaultJobsState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default jobsReducer;
