import { connect } from "react-redux";
import { Loader } from "./index";

const mapStateToProps = state => {
  return {
    isLoading: state.loadingState.isLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export const LoaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Loader);
