export const resourceActions = {
  FETCH_RESOURCE_SUCCESS: `FETCH_RESOURCE_SUCCESS`,
  FETCH_RESOURCE: `FETCH_RESOURCE`,
  FETCH_RESOURCE_ERROR: `FETCH_RESOURCE_ERROR`
};

export const fetchingResource = () => ({
  type: resourceActions.FETCH_RESOURCE
});

export const fetchResourceSuccess = resource => ({  
  type: resourceActions.FETCH_RESOURCE_SUCCESS,
  payload: resource
});

export const fetchResourceError = errorMessage => ({
  type: resourceActions.FETCH_RESOURCE_ERROR
});
