import { connect } from "react-redux";
import { Messenger } from "./index";
import { hideMessage } from "../../../store/actions/messenger-actions";

const mapStateToProps = (state) => {
  return {
    message: state.messengerState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeMessage() {
      dispatch(hideMessage());
    },
  };
};

export const MessengerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Messenger);
