export const personActions = {
  FETCH_PERSON_SUCCESS: `FETCH_PERSON_SUCCESS`,
  FETCH_PERSON: `FETCH_PERSON`,
  FETCH_PERSON_ERROR: `FETCH_PERSON_ERROR`
};

export const fetchingPerson = () => ({
  type: personActions.FETCH_PERSON
});

export const fetchPersonSuccess = person => ({  
  type: personActions.FETCH_PERSON_SUCCESS,
  payload: person
});

export const fetchPersonError = errorMessage => ({
  type: personActions.FETCH_PERSON_ERROR
});
