import React from "react";
import styled from "styled-components";
import Snackbar from "@material-ui/core/Snackbar";
import { SnackbarContent } from "@material-ui/core";

const Close = styled.p`
  opacity: 0.5;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

const CloseText = ({ handleClose }) => (
  <Close onClick={handleClose}>Dismiss</Close>
);

export const Messenger = ({ message, closeMessage }) => {
  const handleClose = () => {
    if (message.open) closeMessage();
  };

  return (
    <Snackbar open={message.open} autoHideDuration={8000} onClose={handleClose}>
      <SnackbarContent
        message={message.message}
        action={<CloseText handleClose={handleClose} />}
      />
    </Snackbar>
  );
};
