import React from "react";
import styled from "styled-components";
import NewJobItem from "../new-job-item";
import Filtering from "../filtering";
import LazyLoad from "react-lazyload";
import { Partner } from "../partner";
import { colors, maxInnerWrapperWidth, media } from "../../../constants/theme";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { paths } from "../../../constants/paths";
import { isFavourited } from "../../../utils/favourites";
import { setFavourite } from "../../../store/actions/favourite-actions";
import { Prices } from "../../../constants/prices";
import { JobCategories } from "../job-categories";
import { metaStatuses } from "../../../store/state/meta-state";
import { ComponentLoader } from "../../atoms/component-loader";
import { TextNotice } from "../../atoms/text-notice";
import { PowerSearchAd } from "../../atoms/power-search-ad";
import { InPartnership } from "../in-partnership";

const Wrapper = styled.div`
  background-color: ${colors.backgroundBlue};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  ${media.desktop`
    padding-left: 32px;
    padding-right: 32px;    
  `}
`;

const Panel = styled.div`
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  background-color: ${colors.white};
  max-width: ${maxInnerWrapperWidth};
  margin: 0 auto;
  width: 100%;
  position: relative;
  top: -80px;
`;
const Left = styled.div`
  display: none;
  ${media.desktop`
    display:block;
    margin-right: 100px;
    width: 30%;
    margin-bottom: 0;
    text-align:left;
  `}
`;
const Right = styled.div`
  width: 100%;
  ${media.desktop`
  width: 70%;
`}
`;

const UnderlinedLink = styled(Link)`
  display: block;
  color: ${colors.linkBlue};
  font-size: 18px;
  font-weight: 600;
  margin: 0px 40px 80px;
  letter-spacing: -0.04em;
  text-align: center;
`;

const Flex = styled.div`
  width: 100%;
  padding: 16px 16px 16px;
  display: flex;
  flex-direction: column;
  ${media.desktop`
    padding: 16px 24px 16px;
    flex-direction: row;
    padding: 60px;
  `}
`;

const JobCategoriesWrapper = styled.div`
  display: block;
  padding: 16px 8px;
  border-bottom: 1px solid #f4f6f7;
`;

const FeaturedJobs = styled.div`
  margin-bottom: 40px;
  ${media.desktop`
    margin-bottom: 80px;
  `}
`;

const Title = styled.h2`
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  padding-bottom: 2px;
  border-bottom: 2px solid ${colors.primaryRed};

  ${media.desktop`
    margin-top: 0;
    margin-bottom: 16px;
    text-align:left;
  `}
`;

export const JobListings = ({
  handleFavouriteClick,
  favourited,
  category,
  filters,
  filtersApplied,
  addFilter,
  removeFilter,
  clearFilters,
  jobs,
  removeFromFilters,
  addToFilters,
  filteredJobs,
  featuredJobs,
  noTopCategories,
  meta: { status },
}) => (
  <Wrapper>
    <Panel>
      {!noTopCategories && (
        <JobCategoriesWrapper>
          <JobCategories small />
        </JobCategoriesWrapper>
      )}
      {status === metaStatuses.SUCCESS && (
        <Flex>
          <Left>
            <Filtering
              category={category}
              filters={filters}
              filtersApplied={filtersApplied}
              addFilter={addFilter}
              removeFilter={removeFilter}
              clearFilters={clearFilters}
              jobs={jobs}
              removeFromFilters={removeFromFilters}
              addToFilters={addToFilters}
            />
            <Partner />
          </Left>
          <Right>
            {/* <InPartnership category={category} /> */}
            {featuredJobs.length > 0 && (
              <>
                <FeaturedJobs>
                  <Title>Featured</Title>
                  {featuredJobs.map((job, i) => (
                    <NewJobItem
                      favourited={isFavourited(job.id, favourited)}
                      handleFavouriteClick={handleFavouriteClick}
                      key={i}
                      job={job}
                    />
                  ))}
                </FeaturedJobs>

                <Title>All Listings</Title>
              </>
            )}

            {filteredJobs.length > 0 ? (
              filteredJobs.map((job, i) => (
                <LazyLoad height={140} offset={2000} key={i} once>
                  <NewJobItem
                    favourited={isFavourited(job.id, favourited)}
                    handleFavouriteClick={handleFavouriteClick}
                    job={job}
                  />
                  {i > 10 && i % 14 === 0 && (
                    <PowerSearchAd
                      tracking={{
                        action: `PowerSearch - ${category} Job Listings - Feed`,
                      }}
                    />
                  )}
                </LazyLoad>
              ))
            ) : (
              <TextNotice hasFrame>
                No positions currently available.
              </TextNotice>
            )}
          </Right>
        </Flex>
      )}

      {(status === metaStatuses.LOADING || status === metaStatuses.DEFAULT) && (
        <ComponentLoader message="Fetching jobs" />
      )}
      {status === metaStatuses.FAILURE && (
        <TextNotice>Error fetching jobs</TextNotice>
      )}
    </Panel>
    <UnderlinedLink to={paths.post_job}>
      List your Remote Job Position for ${Prices.single_job}/30 days
    </UnderlinedLink>
  </Wrapper>
);

const mapDispatchToProps = (dispatch) => ({
  handleFavouriteClick(job) {
    dispatch(setFavourite(job));
  },
});

const mapStateToProps = (state) => {
  return {
    favourited: state.favouriteState.favourites,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobListings);
