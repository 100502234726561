export const companyActions = {
  FETCH_COMPANY_SUCCESS: `FETCH_COMPANY_SUCCESS`,
  FETCH_COMPANY: `FETCH_COMPANY`,
  FETCH_COMPANY_ERROR: `FETCH_COMPANY_ERROR`,
  RESET_COMPANY: "RESET_COMPANY"
};

export const fetchingCompany = () => ({
  type: companyActions.FETCH_COMPANY
});

export const fetchCompanySuccess = company => ({
  type: companyActions.FETCH_COMPANY_SUCCESS,
  payload: company
});

export const fetchCompanyError = err => ({
  type: companyActions.FETCH_COMPANY_ERROR,
  payload: {
    message: err
  }
});

export const resetCompany = () => ({
  type: companyActions.RESET_COMPANY
});
