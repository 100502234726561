import validator from "validator";
import FormValidator from "../../utils/validator";

export const UpdatePasswordValidation = new FormValidator([
  {
    field: "currentPassword",
    method: validator.isEmpty,
    validWhen: false,
    message: "This is a required field",
  },
  {
    field: "newPassword",
    method: validator.isEmpty,
    validWhen: false,
    message: "This is a required field",
  },
  {
    field: "confirmPassword",
    method: validator.isEmpty,
    validWhen: false,
    message: "This is a required field",
  },
]);
