import React from "react";
import styled from "styled-components";
import image from "../../../assets/images/justremote_logo_sml.png";
import { media } from "../../../constants/theme";

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  left: 12px;

  ${media.desktop`
    left: 0;
  `}
`;

const StyledLogo = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  letter-spacing: -0.04em ${media.desktop`
    display:inline-block;
  `};
`;

const LogoIcon = styled.img`
  margin-right: 8px;
  max-width: 24px;
`;

export const Logo = () => {
  return (
    <LogoWrapper>
      <LogoIcon src={image} alt="JustRemote Logo" />
      <StyledLogo>JustRemote</StyledLogo>
    </LogoWrapper>
  );
};
