export const premiumJobsActions = {
  FETCH_PREMIUM_JOBS_SUCCESS: `FETCH_PREMIUM_JOBS_SUCCESS`,
  FETCH_PREMIUM_JOBS: `FETCH_PREMIUM_JOBS`,
  FETCH_PREMIUM_JOBS_ERROR: `FETCH_PREMIUM_JOBS_ERROR`,
};

export const fetchingPremiumJobs = () => ({
  type: premiumJobsActions.FETCH_PREMIUM_JOBS,
});

export const fetchPremiumJobsSuccess = (data) => ({
  type: premiumJobsActions.FETCH_PREMIUM_JOBS_SUCCESS,
  payload: data,
});

export const fetchPremiumJobsError = (errorMessage) => ({
  type: premiumJobsActions.FETCH_PREMIUM_JOBS_ERROR,
  payload: {
    message: errorMessage,
  },
});
