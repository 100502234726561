import { defaultPaymentIntent } from "../state/payment-intent-state";
import { paymentIntentActions } from "../actions/payment-intent-actions";

const handlers = {
  [paymentIntentActions.PAYMENT_INTENT_SUCCESS]: (state, payload) => {
    return { ...state, intent: payload };
  },
};

const paymentIntentReducer = (state = defaultPaymentIntent, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default paymentIntentReducer;
