export const homeJobActions = {
  FETCH_HOME_JOBS_SUCCESS: `FETCH_HOME_JOBS_SUCCESS`,
  FETCH_HOME_JOBS: `FETCH_HOME_JOBS`,
  FETCH_HOME_JOBS_ERROR: `FETCH_HOME_JOBS_ERROR`
};

export const fetchingHomeJobs = () => ({
  type: homeJobActions.FETCH_HOME_JOBS
});

export const fetchHomeJobsSuccess = jobs => ({
  type: homeJobActions.FETCH_HOME_JOBS_SUCCESS,
  payload: jobs
});

export const fetchHomeJobsError = errorMessage => ({
  type: homeJobActions.FETCH_HOME_JOBS_ERROR,
  payload: {
    message: errorMessage
  }
});
