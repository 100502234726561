import { favouriteActions } from "../store/actions/favourite-actions";

export const loadStorage = () => {
  try {
    const serializedState = localStorage.getItem("rwfavourites");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const setStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("rwfavourites", serializedState);
  } catch (err) {
    // Ignore errors
  }
};

const isFavouriteAction = (action) => {
  if (action.type === favouriteActions.SET_FAVOURITE) return true;
  return false;
};

export const lsMiddleware = () => (store) => (next) => (action) => {
  // const previousState = store.getState().favouriteState; // SHOWS the PREVIOUS STATE BEFORE IT CHANGES
  next(action);
  const nextState = store.getState().favouriteState;

  if (isFavouriteAction(action)) setStorage(nextState);
};
