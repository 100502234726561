export const favouriteActions = {
  SET_FAVOURITE: `SET_FAVOURITE`,
  SET_LOCAL_STATE_FAVOURITES: `SET_LOCAL_STATE_FAVOURITES`,
  FETCH_SINGLE_FAVOURITE_JOB: `FETCH_SINGLE_FAVOURITE_JOB`,
  FETCH_SINGLE_FAVOURITE_JOB_SUCCESS: `FETCH_SINGLE_FAVOURITE_JOB_SUCCESS`,
  FETCH_SINGLE_FAVOURITE_JOB_ERROR: `FETCH_SINGLE_FAVOURITE_JOB_ERROR`
};

export const setFavourite = job => ({
  type: favouriteActions.SET_FAVOURITE,
  payload: job
});

export const setLocalStateFavourites = favourites => ({
  type: favouriteActions.SET_LOCAL_STATE_FAVOURITES,
  payload: favourites
});

export const fetchingSingleFavouriteJob = () => ({
  type: favouriteActions.FETCH_SINGLE_FAVOURITE_JOB
});

export const fetchSingleFavouriteJobSuccess = job => ({
  type: favouriteActions.FETCH_SINGLE_FAVOURITE_JOB_SUCCESS,
  payload: job
});

export const fetchSingleFavouriteJobError = errorMessage => ({
  type: favouriteActions.FETCH_SINGLE_FAVOURITE_JOB_ERROR
});
