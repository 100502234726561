import {
  defaultCompaniesState,
  defaultCompaniesEntityState
} from "../state/companies-state";
import { companiesActions } from "../actions/companies-actions";
import { defaultMetaState, metaStatuses } from "../state/meta-state";

const handlers = {
  [companiesActions.FETCH_COMPANIES]: state => {
    return Object.assign({}, state, {
      entity: defaultCompaniesEntityState,
      meta: {
        ...defaultMetaState,
        status: metaStatuses.LOADING
      }
    });
  },

  [companiesActions.FETCH_COMPANIES_ERROR]: (state, payload) => {
    return Object.assign({}, state, {
      entity: defaultCompaniesEntityState,
      meta: {
        status: metaStatuses.FAILURE,
        error: payload.message
      }
    });
  },

  [companiesActions.FETCH_COMPANIES_SUCCESS]: (state, payload) => {
    return Object.assign({}, state, {
      entity: {
        companies: payload
      },
      meta: {
        ...defaultMetaState,
        status: metaStatuses.SUCCESS
      }
    });
  }
};

const companiesReducer = (state = defaultCompaniesState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default companiesReducer;
