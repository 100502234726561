export const companiesActions = {
  FETCH_COMPANIES_SUCCESS: `FETCH_COMPANIES_SUCCESS`,
  FETCH_COMPANIES: `FETCH_COMPANIES`,
  FETCH_COMPANIES_ERROR: `FETCH_COMPANIES_ERROR`
};

export const fetchingCompanies = () => ({
  type: companiesActions.FETCH_COMPANIES
});

export const fetchCompaniesSuccess = companies => ({
  type: companiesActions.FETCH_COMPANIES_SUCCESS,
  payload: companies
});

export const fetchCompaniesError = errorMessage => ({
  type: companiesActions.FETCH_COMPANIES_ERROR,
  payload: {
    message: errorMessage
  }
});
