import { defaultLoadingState } from "../state/loading-state";
import { loadingActions } from "../actions/loading-actions";

const handlers = {
  [loadingActions.SHOW_LOADER]: (state, payload) => {
    return Object.assign({}, state, {
      isLoading: true
    });
  },

  [loadingActions.HIDE_LOADER]: (state, payload) => {
    return Object.assign({}, state, {
      isLoading: false
    });
  }
};

const loadingReducer = (state = defaultLoadingState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default loadingReducer;
