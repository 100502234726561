import { defaultMetaState } from "./meta-state";

export const defaultJobEntityState = {
  all: []
};

export const defaultJobsState = {
  entity: defaultJobEntityState,
  meta: defaultMetaState
};
