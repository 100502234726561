import { defaultNewJobState } from "../state/new-job-state";
import { newJobActions } from "../actions/new-job-actions";

const handlers = {
  [newJobActions.SET_NEW_JOB_DRAFT]: (state, payload) => {
    return Object.assign({}, state, {
      draft: payload
    });
  },
  [newJobActions.POST_NEW_JOB_SUCCESS]: state => {
    return Object.assign({}, state, {
      draft: {
        about_company: "",
        about_role: "",
        apply: "",
        company_logo: [],
        company_name: "",
        company_website: "",
        contact_email: "",
        contact_first_name: "",
        contact_surname: "",
        country_list: [],
        job_category: "",
        job_type: "",
        our_offer: "",
        overlap_hours: 0,
        overlap_required: false,
        specific_country_required: false,
        technology_list: [],
        timezone: "GMT",
        title: "",
        tool_list: [],
        validation: null,
        who_looking_form: ""
      }
    });
  }
};

const newJobReducer = (state = defaultNewJobState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default newJobReducer;
