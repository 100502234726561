import { premiumJobsActions } from "../actions/premium-jobs-actions";
import {
  defaultPremiumJobsState,
  defaultPremiumJobsEntityState,
} from "../state/premium-jobs-state";
import { metaStatuses, defaultMetaState } from "../state/meta-state";

const handlers = {
  [premiumJobsActions.FETCH_PREMIUM_JOBS]: (state) => {
    return Object.assign({}, state, {
      entity: defaultPremiumJobsEntityState,
      meta: {
        ...defaultMetaState,
        status: metaStatuses.LOADING,
      },
    });
  },

  [premiumJobsActions.FETCH_PREMIUM_JOBS_ERROR]: (state, payload) => {
    return Object.assign({}, state, {
      entity: defaultPremiumJobsEntityState,
      meta: {
        status: metaStatuses.FAILURE,
        error: payload.message,
      },
    });
  },
  [premiumJobsActions.FETCH_PREMIUM_JOBS_SUCCESS]: (state, payload) => {
    if (payload.shouldAppend) {
      return {
        ...state,
        entity: {
          ...state.entity,
          jobs: [...state.entity.jobs, ...payload.data.jobs],
          count: payload.data.count,
        },
        meta: {
          ...defaultMetaState,
          status: metaStatuses.SUCCESS,
        },
      };
    }
    return {
      ...state,
      entity: {
        ...state.entity,
        jobs: payload.data.jobs,
        count: payload.data.count,
      },
      meta: {
        ...defaultMetaState,
        status: metaStatuses.SUCCESS,
      },
    };
  },
};

const premiumJobsReducer = (state = defaultPremiumJobsState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default premiumJobsReducer;
