import { defaultEmailFormState } from "../state/email-form-state";
import { emailFormActions } from "../actions/email-form-actions";

const handlers = {
  [emailFormActions.SHOW_EMAIL_FORM]: (state, payload) => {
    return Object.assign({}, state, {
      open: true
    });
  },

  [emailFormActions.HIDE_EMAIL_FORM]: (state, payload) => {
    return Object.assign({}, state, {
      open: false
    });
  }
};

const emailFormReducer = (state = defaultEmailFormState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default emailFormReducer;
