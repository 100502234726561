import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import loader from '../../../assets/images/loader.svg';

const Spin = keyframes`
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
`

const LoaderWrap = styled.div`
  position:fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom:0;
  height: 100%;
  width:100%;
  background-color: rgba(255,255,255,0.9);
  z-index: 999;
  display:flex;
  align-items:center;
`;

const Planet = styled.img`
  display:block;
  margin: 0 auto;
  width: 56px;
  animation: ${Spin} 1s infinite linear;
`;
  
export class Loader extends Component {
  render() {
    if( this.props.isLoading ) {
      return (
        <LoaderWrap>
          <Planet src={loader} />
        </LoaderWrap>
      );
    }
    return null;
  }
}