import { defaultUserState } from "../state/user-state";
import { userActions } from "../actions/user-actions";

const handlers = {
  [userActions.FETCH_USER_ERROR]: (state, payload) => {
    return Object.assign({}, state, {});
  },

  [userActions.FETCH_USER_SUCCESS]: (state, payload) => {
    return Object.assign({}, state, {
      email: payload.email,
    });
  },

  [userActions.RESET_USER]: (state, payload) => {
    return Object.assign({}, state, defaultUserState);
  },
};

const userReducer = (state = defaultUserState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default userReducer;
