import axios from "axios";
import { setUrlBase, setPremiumUrlBase } from "./set-url-base";
import { getCookie } from "./cookies";
import { COOKIE_NAMES } from "../constants/cookies";

export const Server = (props, isApp) => {
  const URL_BASE = isApp ? setPremiumUrlBase() : setUrlBase();
  // const URL_BASE = "https://justremote-api.herokuapp.com";
  let token;
  if (props.isPrivate) {
    token = getCookie(COOKIE_NAMES.TOKEN);
  }

  return axios({
    method: props.method,
    url: `${URL_BASE}${props.url}`,
    data: props.data ? props.data : null,
    headers: token ? { Authorization: `Bearer ${token}` } : null,
  });
};
