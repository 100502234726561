import React, { Component } from "react";
import styled from "styled-components";
import { RadioChips } from "../../atoms/radio-chips";
import { media } from "../../../constants/theme";
import { withRouter } from "react-router";
import { Filter } from "../../atoms/filter";
import { FilterLabel } from "../../atoms/filter-label";
import { paths } from "../../../constants/paths";
import { setCategory } from "../../../utils/set-category";

const FiltersWrapper = styled.div`
  display: none;
  ${media.desktop`
    display: block;
    width: 100%;
    z-index: 9;   
    color: rgba(0, 0, 0, 0.86);   
  `}
`;

const Filters = styled.div`
  margin: 0;
  margin-right: 48px;
`;

const ClearFilters = styled.div`
  display: flex;
  flex-direction: row;  
  font-size: 20px;
  letter-spacing: 1px;
  white-space: nowrap;
  align-items: center;
  margin-bottom: 24px;

  i {
    margin-left: 5px;
  }

  &:hover {
    cursor: pointer;
  }

  ${media.desktop`
    font-size: 14px;
  `};
}`;

const DesktopClear = styled.span`
  color: blue;
`;

export class Filtering extends Component {
  render() {
    const {
      addFilter,
      removeFilter,
      category,
      filters,
      filtersApplied,
      clearFilters,
      jobs,
      removeFromFilters,
      addToFilters,
      location,
    } = this.props;

    const handleClick = (chip, chipType, filterCat) => {
      const filter = {
        category: setCategory(category).replace(/\s/g, ""),
        filterCat: filterCat,
        data: chip.selected && chipType === "Radio" ? null : chip.label,
      };
      if (chip.selected) {
        chipType === "Radio" ? removeFilter(filter) : removeFromFilters(filter);
      } else {
        chipType === "Radio" ? addFilter(filter) : addToFilters(filter);
      }
    };

    const buildChipList = (jobs, prop) => {
      return jobs.map((job) => job[prop]);
    };

    const dedupeData = (jobs, prop) => {
      return buildChipList(jobs, prop)
        .filter((elem, pos, arr) => {
          return arr.indexOf(elem) === pos;
        })
        .sort();
    };

    const dedupeTechnologies = (jobs) => {
      let uniqTechnologyList = [];
      jobs.map((job) => {
        let techList = job.technology_list;
        if (techList && techList.length > 0) {
          return techList.map((tech) => {
            if (uniqTechnologyList.indexOf(tech.label) === -1)
              uniqTechnologyList.push(tech.label);
            return null;
          });
        }
        return null;
      });
      uniqTechnologyList.sort();
      return uniqTechnologyList;
    };

    const technologyList = dedupeTechnologies(jobs);

    const countryList = dedupeData(jobs, "job_country");

    return (
      <>
        <FiltersWrapper>
          <Filters>
            <Filter>
              <FilterLabel>Job Type</FilterLabel>
              <RadioChips
                chips={[
                  {
                    label: "Permanent",
                    selected:
                      filters.job_type && filters.job_type === "Permanent"
                        ? true
                        : false,
                  },
                  {
                    label: "Contract",
                    selected:
                      filters.job_type && filters.job_type === "Contract"
                        ? true
                        : false,
                  },
                ]}
                click={(chip) => handleClick(chip, "Radio", "job_type")}
              />
            </Filter>
            {jobs.length > 0 && (
              <>
                <Filter>
                  <FilterLabel>Job Origin</FilterLabel>
                  <RadioChips
                    chips={countryList.map((country) => {
                      return {
                        label: country,
                        selected:
                          filters.job_country &&
                          filters.job_country.find(
                            (location) => location === country
                          )
                            ? true
                            : false,
                      };
                    })}
                    click={(chip) =>
                      handleClick(chip, "Checkbox", "job_country")
                    }
                  />
                </Filter>
                {location.pathname === paths.developer_jobs && (
                  <Filter>
                    <FilterLabel>Technology</FilterLabel>
                    <RadioChips
                      chips={technologyList.map((technology) => {
                        return {
                          label: technology,
                          selected:
                            filters.technology &&
                            filters.technology.find(
                              (tech) => tech === technology
                            )
                              ? true
                              : false,
                        };
                      })}
                      click={(chip) =>
                        handleClick(chip, "Checkbox", "technology")
                      }
                    />
                  </Filter>
                )}
              </>
            )}
            {filtersApplied && (
              <ClearFilters onClick={() => clearFilters(setCategory(category))}>
                <DesktopClear>Clear all filters</DesktopClear>
              </ClearFilters>
            )}
          </Filters>
        </FiltersWrapper>
      </>
    );
  }
}

export default withRouter(Filtering);
