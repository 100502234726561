export const defaultPersonState = {
  uid: '' ,
  name: '',
  shortName: '',
  questionOne: '',
  questionTwo: '',
  questionThree: '',
  questionFour: '',
  questionFive: '',
  questionSix: '',
  questionSeven: '',
  questionEight: '',
  image: null,
  website: '',
  remoteSince: '',
  category: '',
  instagram: '',
  twitter: '',
  medium: '',
  facebook: '',
  linkedint: ''
};