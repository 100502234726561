import React from "react";
import styled, { keyframes } from "styled-components";
import loader from "../../../assets/images/loader.svg";

const Spin = keyframes`
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
`;

const Wrapper = styled.div`
  padding: 100px;
  text-align: center;
`;

const Planet = styled.img`
  display: block;
  margin: 0 auto;
  width: 56px;
  animation: ${Spin} 1s infinite linear;
`;

export const ComponentLoader = ({ message }) => {
  return (
    <Wrapper>
      <Planet src={loader} />
      {message && <p>{message}</p>}
    </Wrapper>
  );
};
