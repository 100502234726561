import { subscriptionActions } from "../actions/subscription-actions";
import {
  defaultSubscriptionEntityState,
  defaultSubscriptionState,
} from "../state/subscription-state";
import { metaStatuses, defaultMetaState } from "../state/meta-state";

const handlers = {
  [subscriptionActions.FETCH_SUBSCRIPTION]: (state) => {
    return Object.assign({}, state, {
      entity: defaultSubscriptionEntityState,
      meta: {
        ...defaultMetaState,
        status: metaStatuses.LOADING,
      },
    });
  },

  [subscriptionActions.FETCH_SUBSCRIPTION_ERROR]: (state, payload) => {
    return Object.assign({}, state, {
      entity: defaultSubscriptionEntityState,
      meta: {
        status: metaStatuses.FAILURE,
        error: payload.message,
      },
    });
  },
  [subscriptionActions.FETCH_SUBSCRIPTION_SUCCESS]: (state, payload) => {
    const nextPayment = new Date(payload.current_period_end * 1000);

    const formattedDate = `${nextPayment.getDate()}/${
      nextPayment.getMonth() + 1
    }/${nextPayment.getFullYear()}`;

    return Object.assign({}, state, {
      entity: {
        isActive: !payload.cancel_at_period_end,
        nextPayment: formattedDate,
      },
      meta: {
        ...defaultMetaState,
        status: metaStatuses.SUCCESS,
      },
    });
  },
};

const subscriptionReducer = (state = defaultSubscriptionState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default subscriptionReducer;
