import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { media } from "../../../constants/theme";

const StyledChip = styled.div`
  background-color: ${props =>
    props.selected ? "hsla(163,100%,50%,1)" : "#fff"};
  color: ${props => (props.selected ? "hsla(163,100%,20%,1)" : "#1b1b1b")};
  border: 1px solid
    ${props => (props.selected ? "hsla(163,100%,50%,1)" : "rgba(0,0,0,0.05)")};
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  letter-spacing: 0.03em;
  font-weight: 300;
  border-radius: 3px;
  padding: 5px 10px;
  user-select: none;
  text-transform: capitalize;
  ${props => (props.hoverable ? `&:hover {cursor: pointer;}` : null)}
  ${props =>
    props.dark &&
    `
    background-color: ${props.selected ? "#1b1b1b" : "#fff"};
    color: ${props.selected ? "#fff" : "#1b1b1b"};
    border: 1px solid ${props.selected ? "#fff" : "rgba(0,0,0,0.05)"};
  `}
`;

export const Chip = props => {
  return <StyledChip>{props.label}</StyledChip>;
};

export const SelectableChip = props => {
  const { label, selected, click, dark } = props;
  return (
    <StyledChip
      dark={dark ? true : false}
      selected={selected}
      onClick={click}
      hoverable
    >
      {label}
    </StyledChip>
  );
};

export const LinkChip = props => {
  const { label, path, selected } = props;
  return (
    <Link to={path}>
      <StyledChip selected={selected} hoverable>
        {label}
      </StyledChip>
    </Link>
  );
};
