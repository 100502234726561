import React from "react";
import styled from "styled-components";
import { StyledTooltip } from "../tooltip";

const StyledLabel = styled.label`
  margin-bottom: 8px;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;
  font-weight: 600;
`;

export const Label = props => {
  return (
    <StyledLabel>
      {props.title}
      {props.tooltipTitle ? <StyledTooltip title={props.tooltipTitle} /> : null}
    </StyledLabel>
  );
};
