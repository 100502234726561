import { defaultArticleState } from "../state/article-state";
import { articleActions } from "../actions/article-actions";

const handlers = {
  [articleActions.FETCH_ARTICLE_ERROR]: (state, payload) => {
    return Object.assign({}, state, {
      error: payload
    });
  },

  [articleActions.FETCH_ARTICLE_SUCCESS]: (state, payload) => {
    const data = payload.data;
    return Object.assign({}, state, {
      uid: payload.uid,
      title: data.title,
      content: data.content,
      author: data.author,
      meta_description: data.meta_description[0].text,
      social_share_image: data.social_share_image.url,
      date_published: payload.first_publication_date,
      date_modified: payload.last_publication_date
    });
  }
};

const articleReducer = (state = defaultArticleState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default articleReducer;
