export const setUrlBase = () => {
  return process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "https://justremote-api.herokuapp.com";
};

export const setPremiumUrlBase = () => {
  // return "https://justremote-premium-api.herokuapp.com";
  return process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "https://justremote-premium-api.herokuapp.com";
};
