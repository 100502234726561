import { defaultResourceState } from "../state/resource-state";
import { resourceActions } from "../actions/resource-actions";

const handlers = {
  [resourceActions.FETCH_RESOURCE_ERROR]: (state, payload) => {
    return Object.assign({}, state, {
      error: payload
    });
  },

  [resourceActions.FETCH_RESOURCE_SUCCESS]: (state, payload) => {
    const data = payload.data;
    return Object.assign({}, state, {
      uid: payload.uid,
      title: data.name[0].text,
      summary: data.summary,
      description: data.description,
      logo: data.logo,
      hero_image_mobile: data.hero_image_mobile,
      hero_image_desktop: data.hero_image_desktop,
      footer_image_one: data.footer_image_one,
      footer_image_two: data.footer_image_two,
      footer_image_three: data.footer_image_three,
      review: data.review,
      faqs: data.faqs,
      category: data.category,
      url: data.url[0].text,
      meta_description: data.meta_description[0].text,
      social_share_image: data.social_share_image.url
    });
  }
};

const resourceReducer = (state = defaultResourceState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default resourceReducer;
