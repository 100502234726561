export const newJobActions = {
  SET_NEW_JOB_DRAFT: `SET_NEW_JOB_DRAFT`,
  POST_NEW_JOB: `POST_NEW_JOB`,
  POST_NEW_JOB_ERROR: `POST_NEW_JOB_ERROR`,
  POST_NEW_JOB_SUCCESS: `POST_NEW_JOB_SUCCESS`,
};

export const setNewJobDraft = draft => ({
  type: newJobActions.SET_NEW_JOB_DRAFT,
  payload: draft
});

export const postNewJob = () => ({
  type: newJobActions.POST_NEW_JOB
});

export const postNewJobSuccess = () => ({
  type: newJobActions.POST_NEW_JOB_SUCCESS
});

export const postNewJobError = errorMessage => ({
  type: newJobActions.POST_NEW_JOB_ERROR
});