import React from "react";
import Modal from "../app/components/modal";
import Banner from "./atoms/banner";
import styled, { createGlobalStyle } from "styled-components";
import { renderRoutes } from "react-router-config";
import { Helmet } from "react-helmet";
import { Navbar } from "../app/components/navbar";
import { MessengerContainer } from "../app/components/messenger/messengerContainer";
import { LoaderContainer } from "../app/components/loader/loaderContainer";
import { EmailFormContainer } from "../app/components/email-form/emailFormContainer";

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    padding: 0;
    margin:0;
    font-family: "europa", sans-serif;
    font-display: swap;
  }


  input, textarea, select {
    font-family: "europa", sans-serif;
    font-display: swap;
    letter-spacing: 0.03rem;
    font-weight: 400;
  }

  img {
    max-width: 100%;
    display:block;
  }

  * {
    box-sizing: border-box;
  }

  *:focus {
    outline:none;
  }
  h1,h2,h3,h4,h5 {
    margin: 0;
    padding: 0
  }
  ul {
    margin-left: 0px;
    padding: 0; 
  }
  a {
    text-decoration:none;
    color: #1b1b1b;
  }

  p {
    margin-bottom: 15px;
  }

  #carbonads {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    position: relative;
  }

`;

const AppWrapper = styled.div``;

const App = (props) => {
  const BASE_URL =
    process.env.NODE_ENV === "production"
      ? "https://justremote.co"
      : "local.justremote.co:3000";
  return (
    <AppWrapper>
      <Helmet>
        <meta
          property="og:url"
          content={`${BASE_URL}${props.location.pathname}`}
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <GlobalStyle />
      <Banner />
      <Navbar page={props.location.pathname} />
      {renderRoutes(props.route.routes)}
      {/* <EmailFormContainer /> */}
      <MessengerContainer />
      <LoaderContainer />
      <Modal />
    </AppWrapper>
  );
};

export default App;
