import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors } from "../../../constants/theme";

const StyledLink = styled(Link)`
  margin: 4px;
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const Wrapper = styled.div`
  background-color: ${colors.secondaryNeutral};
  border-radius: 30px;
  font-size: 18px;
  padding: 18px 24px;

  ${props =>
    props.isSmall &&
    `
    padding: 8px 12px;
    font-size: 14px;
  `}
`;

export const CategoryLink = props => (
  <StyledLink to={props.path}>
    <Wrapper isSmall={props.isSmall}>{props.title}</Wrapper>
  </StyledLink>
);
