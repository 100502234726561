import React from "react";
import styled from "styled-components";
import { colors, media } from "../../../constants/theme";
import { paths } from "../../../constants/paths";
import { CategoryLink } from "../../atoms/category-link";

const Wrapper = styled.div`
  display: flex;
  padding: 60px 24px;
  align-items: center;
  flex-direction: column;
  ${media.desktop`
    padding: 100px 100px 130px
  `}
`;

const Heading = styled.h2`
  margin-bottom: 40px;
`;

const Triangle = styled.svg`
  margin-bottom: 16px;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const PopularCategories = () => {
  return (
    <Wrapper>
      <Triangle
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7 0L13.9282 12H0.0717969L7 0Z" fill={colors.primaryRed} />
      </Triangle>
      <Heading>Popular Job Categories</Heading>
      <LinksWrapper>
        <CategoryLink path={paths.all_jobs} title="All" />
        <CategoryLink path={paths.manager_jobs} title="Business/Exec" />
        <CategoryLink
          path={paths.customer_service_jobs}
          title="Customer Service"
        />
        <CategoryLink path={paths.developer_jobs} title="Developer" />
        <CategoryLink
          path={paths.devops_sysadmin_jobs}
          title="Devops & Sysadmin"
        />
        <CategoryLink path={paths.design_jobs} title="Design" />
        <CategoryLink path={paths.editing_jobs} title="Editing" />
        <CategoryLink path={paths.marketing_jobs} title="Marketing" />
        <CategoryLink path={paths.hr_jobs} title="HR" />
        <CategoryLink
          path={paths.project_manager_jobs}
          title="Project Manager"
        />
        <CategoryLink path={paths.recruiter_jobs} title="Recruiter" />
        <CategoryLink path={paths.sales_jobs} title="Sales" />
        <CategoryLink path={paths.seo_jobs} title="SEO" />
        <CategoryLink path={paths.social_media_jobs} title="Social Media" />
        <CategoryLink path={paths.writing_jobs} title="Writing" />
      </LinksWrapper>
    </Wrapper>
  );
};
