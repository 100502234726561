export const filterActions = {
  ADD_FILTER: `ADD_FILTER`,
  REMOVE_FILTER: `REMOVE_FILTER`,
  CLEAR_FILTERS: `CLEAR_FILTERS`,
  REMOVE_FROM_FILTERS: `REMOVE_FROM_FILTERS`,
  ADD_TO_FILTERS: `ADD_TO_FILTERS`
};

export const addFilter = filter => ({
  type: filterActions.ADD_FILTER,
  payload: filter
});

export const removeFilter = filter => ({
  type: filterActions.REMOVE_FILTER,
  payload: filter
});

export const clearFilters = category => ({
  type: filterActions.CLEAR_FILTERS,
  payload: category
});

export const removeFromFilters = filter => ({
  type: filterActions.REMOVE_FROM_FILTERS,
  payload: filter
});

export const addToFilters = filter => ({
  type: filterActions.ADD_TO_FILTERS,
  payload: filter
});
