import {
  defaultHomeJobsState,
  defaultHomeJobsEntityState,
} from "../state/home-job-state";
import { homeJobActions } from "../actions/home-job-actions";
import { metaStatuses, defaultMetaState } from "../state/meta-state";

const handlers = {
  [homeJobActions.FETCH_HOME_JOBS]: (state) => {
    return Object.assign({}, state, {
      entity: defaultHomeJobsEntityState,
      meta: {
        ...defaultMetaState,
        status: metaStatuses.LOADING,
      },
    });
  },

  [homeJobActions.FETCH_HOME_JOBS_ERROR]: (state, payload) => {
    return Object.assign({}, state, {
      entity: defaultHomeJobsEntityState,
      meta: {
        status: metaStatuses.FAILURE,
        error: payload.message,
      },
    });
  },

  [homeJobActions.FETCH_HOME_JOBS_SUCCESS]: (state, payload) => {
    return Object.assign({}, state, {
      entity: {
        jobs: payload,
      },
      meta: {
        ...defaultMetaState,
        status: metaStatuses.SUCCESS,
      },
    });
  },
};

const homeJobsReducer = (state = defaultHomeJobsState, action) => {
  return handlers.hasOwnProperty(action.type)
    ? handlers[action.type](state, action.payload)
    : state;
};

export default homeJobsReducer;
