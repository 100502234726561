import React, { PureComponent } from "react";
import LazyLoad from "react-lazyload";
import styled from "styled-components";
import DynamicTags from "../dynamic-tags";
import { media } from "../../../constants/theme";
import { TrackClick } from "../../../utils/analytics";
import { withRouter } from "react-router-dom";
import { HeartFilled, HeartEmpty } from "../../atoms/favourite-button";

const JobItemWrapper = styled.div`
  padding: 16px 0;
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: #fff;
`;
const JobItemInnerWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;
const LogoContainer = styled.div`
  display: none;

  ${media.desktop`  
    display: block;
    width: 30px;
    height: 30px;
    background:url(${(props) => props.url}) center center no-repeat;
    background-size: contain;
    margin-right: 18px;
    margin-top: 8px;
  `}
`;

const JobMetaInner = styled.div`
  width: 100%;
`;

const JobItemCompany = styled.div`
  display: inline-block;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 16px;
  letter-spacing: -0.03em;
`;

const JobItemDate = styled.div`
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.4);
`;
const HeartIcon = styled.div`
  cursor: pointer;
  display: block;
  position: absolute;
  right: 0;
  top: 20px;
`;
const JobMeta = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }
`;
const JobTitle = styled.h3`
  font-size: 16px;
  ${media.desktop`
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  `}
`;

const JobTags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Tag = styled.div`
  background-color: #f3f3f3;
  border-radius: 3px;
  padding: 4px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  letter-spacing: -0.03em;
  margin-right: 16px;
  text-transform: capitalize;
  margin-bottom: 4px;

  @media (min-width: 375px) {
    padding: 4px 8px;
  }

  ${media.tablet`
    padding: 4px 12px;
  `}
`;

const BottomRow = styled.div`
  display: flex;
  margin-top: 16px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const JobInnerWrapper = styled.div`
  width: 100%;
`;

export class NewJobItem extends PureComponent {
  handleFavouriteClick = () => {
    this.props.handleFavouriteClick(this.props.job);
  };

  handleJobClick = (e) => {
    e.preventDefault();

    const { history, job } = this.props;

    TrackClick({
      category: "Job Item",
      action: `Link Clicked - ${job.category}`,
      label: job.href,
    });

    history.push(job.href);
  };

  render() {
    const { favourited, job } = this.props;

    return (
      <JobItemWrapper>
        <LazyLoad offset={400} height={30}>
          {job.logo && job.logo.url && <LogoContainer url={job.logo.url} />}
        </LazyLoad>
        <HeartIcon>
          {favourited ? (
            <HeartFilled onClick={this.handleFavouriteClick} height="20" />
          ) : (
            <HeartEmpty onClick={this.handleFavouriteClick} height="20" />
          )}
        </HeartIcon>
        <JobItemInnerWrap>
          <JobInnerWrapper>
            <JobMeta href={job.href} onClick={this.handleJobClick}>
              <JobMetaInner>
                <JobItemCompany>{job.company_name}</JobItemCompany>
                <JobTitle>{job.title}</JobTitle>
              </JobMetaInner>
            </JobMeta>
            <BottomRow>
              <JobTags>
                <Tag>{job.job_type}</Tag>
                {job.technology_list && <DynamicTags job={job} />}
              </JobTags>
              <JobItemDate>{job.date}</JobItemDate>
            </BottomRow>
          </JobInnerWrapper>
        </JobItemInnerWrap>
      </JobItemWrapper>
    );
  }
}

export default withRouter(NewJobItem);
