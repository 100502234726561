import { Server } from "../../utils/server";
import { BASE_JOBS_API } from "../../constants/paths";
import {
  fetchingJobs,
  fetchJobsError,
  fetchAllJobsSuccess,
} from "../actions/jobs-actions";

export const fetchJobs = () => (dispatch) => {
  dispatch(fetchingJobs());

  return Server({
    method: "get",
    url: BASE_JOBS_API,
  })
    .then((response) => response.data)
    .then((jobs) => {
      dispatch(fetchAllJobsSuccess(jobs));
    })
    .catch((err) => {
      const message = "Error fetching jobs";
      dispatch(fetchJobsError(message));
    });
};
