import React from "react";
import styled from "styled-components";
import { media } from "../../../constants/theme";
import { Link } from "react-router-dom";
import { skills } from "../../../constants/dynamic-developer-route-skills";

const StyledTag = styled(Link)`
  background-color: #f3f3f3;
  border-radius: 3px;
  padding: 4px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  letter-spacing: -0.03em;
  margin-right: 16px;
  margin-bottom: 4px;

  @media (min-width: 375px) {
    padding: 4px 8px;
  }

  ${media.tablet`
    padding: 4px 12px;
  `}
`;

export default ({ job }) => {
  const formattedTechnologies = job.technology_list.map((technology) => {
    const lowercase = technology.label.toLowerCase();
    let modified = lowercase;
    if (lowercase === "nodejs") modified = "node";
    if (lowercase === "reactjs") modified = "react";
    if (lowercase === "ruby on rails") modified = "ruby-on-rails";
    if (lowercase === ".net") modified = "asp-net";
    return modified;
  });

  const linkableTech = formattedTechnologies.filter((technology) =>
    skills.some((skill) => skill === technology)
  );

  return linkableTech.map((tech, i) => {
    let label = tech.replace(/^\w/, (c) => c.toUpperCase());
    let path = tech;

    if (label === "Php") label = "PHP";
    if (label === "Asp-net") label = "ASP.Net";
    if (label === "Ruby-on-rails") label = "Ruby on Rails";
    if (label === "Aws") label = "AWS";
    if (label === "Node") {
      label = "NodeJS";
      path = "node-js";
    }
    if (label === "Ios") {
      label = "iOS";
      path = "ios-developer";
    }
    if (label === "React") {
      label = "ReactJS";
      path = "react-developer";
    }
    if (label === "Java") {
      path = "java-developer";
    }

    return (
      <StyledTag key={`${tech}-${i}`} to={`/${path}-jobs`}>
        {label}
      </StyledTag>
    );
  });
};
