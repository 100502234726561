import styled from "styled-components";

export const TextNotice = styled.div`
  width: 100%;
  text-align: center;
  padding: 100px 24px;
  ${props =>
    props.hasFrame &&
    `
    border: 1px solid #f4f6f7;
  `}
`;
