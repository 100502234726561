export const modalActions = {
  OPEN_MODAL: `OPEN_MODAL`,
  CLOSE_MODAL: `CLOSE_MODAL`,
};

export const openModal = (modalId, data) => ({
  type: modalActions.OPEN_MODAL,
  payload: {
    modalId,
    data,
  },
});

export const closeModal = () => ({
  type: modalActions.CLOSE_MODAL,
});
