import { css } from "styled-components";

export const colors = {
  primaryRed: "#f15d5d",
  backgroundBlue: "#F5F8FF",
  linkBlue: "#0057FF",
  secondaryNeutral: "rgba(255, 220, 191, 0.5)",
  lighterText: "#B0B0B0",
  blackText: "#000",
  white: "#FFF",
  yellow: "#FFC700",
  positiveGreen: "#0fd887",
  darkBlue: "#262a33",
};

export const fonts = {};

export const spacing = {};

export const maxWrapperWidth = "1400px";
export const maxInnerWrapperWidth = "1200px";

const sizes = {
  ultra: 1400,
  giant: 1170,
  desktop: 992,
  tablet: 768,
  phone: 375,
};

export const media = Object.keys(sizes).reduce((accumulator, label) => {
  const emSize = sizes[label] / 16;
  accumulator[label] = (...args) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)}
    }
  `;
  return accumulator;
}, {});
