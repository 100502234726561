import { Server } from "../../utils/server";
import { BASE_API_VERSION, endpoints } from "../../constants/endpoints";
import { showMessage } from "../actions/messenger-actions";
import { closeModal } from "../actions/modal-actions";

export const updatePassword = (data) => async (dispatch) => {
  try {
    await Server(
      {
        method: "put",
        url: `${BASE_API_VERSION}${endpoints.UPDATE_PASSWORD}`,
        data,
        isPrivate: true,
      },
      true // isApp
    );

    dispatch(closeModal());
    dispatch(showMessage("Success! Your password has been updated."));
  } catch (err) {
    dispatch(
      showMessage(
        "Error updating password. Please check your current password and try again."
      )
    );
  }
};
