import React from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { paths } from "../../../constants/paths";
import { media, colors } from "../../../constants/theme";
import { TrackClick } from "../../../utils/analytics";
import { CountBadgeContainer } from "../count-badge";

const NavbarLinksWrapper = styled.div`
  ${media.desktop`
    display:flex;
    flex-direction: row;
    align-items:center;

    a {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
    }
  `}
`;

const DesktopOnlyLinks = styled.div`
  display: none;
  ${media.desktop`
    display:block;
    a {
      margin-right: 24px;
    }
  `}
`;

const PostJob = styled.div`
  display: inline-block;

  a {
    border: 2px solid ${colors.primaryRed};
    padding: 10px 15px;
    border-radius: 4px;
    font-weight: 600;
    margin-right: 0;
  }
`;

const IconsWrapper = styled.div`
  margin-left: 24px;
`;

const PowerSearch = styled(Link)`
  color: ${colors.linkBlue};
  font-weight: 600 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const NavbarLinks = ({ location }) => {
  if (location.pathname === paths.sign_in) {
    return (
      <Link
        to={paths.sign_up}
        onClick={() =>
          TrackClick({
            category: "Navigation - Navbar",
            action: "Link Clicked",
            label: "Power Search - Sign up",
          })
        }
      >
        Sign up
      </Link>
    );
  }

  if (
    location.pathname === paths.power_search ||
    location.pathname === paths.sign_up ||
    location.pathname === paths.forgotten_password ||
    location.pathname === paths.subscribe
  ) {
    return (
      <Link
        to={paths.sign_in}
        onClick={() =>
          TrackClick({
            category: "Navigation - Navbar",
            action: "Link Clicked",
            label: "Power Search - Sign In",
          })
        }
      >
        Sign in
      </Link>
    );
  }

  return (
    <NavbarLinksWrapper>
      <DesktopOnlyLinks>
        <PowerSearch
          to={paths.power_search}
          onClick={() =>
            TrackClick({
              category: "PowerSearch",
              action: `PowerSearch - ${location.pathname} - Navbar`,
              label: "PowerSearch - Navbar",
            })
          }
        >
          Power Search
        </PowerSearch>
        <Link
          to={paths.all_jobs}
          onClick={() =>
            TrackClick({
              category: "Navigation - Navbar",
              action: "Link Clicked",
              label: "Remote Jobs",
            })
          }
        >
          Remote Jobs
        </Link>
        <Link
          to={paths.companies}
          onClick={() =>
            TrackClick({
              category: "Navigation - Navbar",
              action: "Link Clicked",
              label: "Remote Companies",
            })
          }
        >
          Remote Companies
        </Link>
        <PostJob>
          <Link
            to={paths.post_job}
            onClick={() =>
              TrackClick({
                category: "Navigation - Navbar",
                action: "Link Clicked",
                label: "Post Job",
              })
            }
          >
            List your position
          </Link>
        </PostJob>
      </DesktopOnlyLinks>

      <IconsWrapper>
        <Link to={paths.favourite_jobs} aria-label="Favourites" rel="nofollow">
          <CountBadgeContainer />
        </Link>
      </IconsWrapper>
    </NavbarLinksWrapper>
  );
};

export default withRouter(NavbarLinks);
