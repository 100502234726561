import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { closeModal } from "../../../store/actions/modal-actions";
import { useEffect } from "react";
import { useRef } from "react";
import { media } from "../../../constants/theme";
import { MODAL_IDS } from "../../../constants/modal-ids";
import { UpdatePasswordModal } from "../update-password-modal";
import { UpdatePaymentMethodModal } from "../update-payment-method-modal";
import { BreakdownJobsByCategory } from "../breakdown-jobs-by-category-modal";

const Background = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 8;
`;

const ModalPanel = styled.div`
  position: relative;
  max-width: 300px;
  background-color: #fff;
  border-radius: 5px;
  z-index: 9;
  margin-left: 16px;
  margin-right: 16px;
  padding: 16px;
  max-height: 90vh;
  overflow-y: scroll;
  ${media.desktop`
    max-width: 500px;
    padding: 50px;
  `}
`;

export const modalContent = (id, data) => {
  switch (id) {
    case MODAL_IDS.UPDATE_PAYMENT_METHOD:
      return <UpdatePaymentMethodModal />;
    case MODAL_IDS.UPDATE_PASSWORD:
      return <UpdatePasswordModal />;
    case MODAL_IDS.BREAKDOWN_JOBS_BY_CATEGORY:
      return <BreakdownJobsByCategory data={data} />;
  }
};
export const Modal = ({ isOpen, modalId, closeModal, data }) => {
  const ref = useRef();

  const handleClick = (e) => {
    if (isOpen && ref.current && !ref.current.contains(e.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return function cleanup() {
      document.removeEventListener("click", handleClick);
    };
  });

  if (isOpen) {
    return (
      <Background>
        <ModalPanel ref={ref}>{modalContent(modalId, data)}</ModalPanel>
      </Background>
    );
  }
  return null;
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal()),
});

const mapStateToProps = (state) => ({
  isOpen: state.modalState.open,
  modalId: state.modalState.modalId,
  data: state.modalState.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
