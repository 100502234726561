import React from "react";
import styled from "styled-components";
import { media } from "../../../constants/theme";

const Wrapper = styled.div`
  min-width: 280px;
`;

const Count = styled.div`
  font-size: 30px;
  font-weight: 600;
  line-height: 1em;
  margin-right: 16px;
  ${media.tablet`
    font-size: 48px;
  `}
`;

const CountWrapper = styled.div`
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  ${media.tablet`
    margin-bottom: 24px;
  `}
`;

export const BreakdownJobsByCategory = ({ data }) => {
  return (
    <Wrapper>
      <CountWrapper>
        <Count>{data.customer_success_count}</Count>
        <div>Customer Success Jobs</div>
      </CountWrapper>
      <CountWrapper>
        <Count>{data.design_count}</Count>
        <div>Design Jobs</div>
      </CountWrapper>
      <CountWrapper>
        <Count>{data.dev_count}</Count>
        <div>Developer Jobs</div>
      </CountWrapper>
      {/* <CountWrapper>
        <Count>{data.hr_count}</Count>
        <div>HR</div>
      </CountWrapper> */}
      <CountWrapper>
        <Count>{data.marketing_count}</Count>
        <div>Marketing Jobs</div>
      </CountWrapper>
      <CountWrapper>
        <Count>{data.manager + data.project_management_count}</Count>
        <div>Manager/Project Management Jobs</div>
      </CountWrapper>
      <CountWrapper>
        <Count>{data.sales_count}</Count>
        <div>Sales Jobs</div>
      </CountWrapper>
      <CountWrapper>
        <Count>{data.writing_count}</Count>
        <div>Writing Jobs</div>
      </CountWrapper>
    </Wrapper>
  );
};
