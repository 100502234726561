export const paymentActions = {
  MAKE_PAYMENT_SUCCESS: `MAKE_PAYMENT_SUCCESS`,
  MAKE_PAYMENT: `MAKE_PAYMENT`,
  MAKE_PAYMENT_ERROR: `MAKE_PAYMENT_ERROR`,
};

export const makePayment = () => ({
  type: paymentActions.MAKE_PAYMENT,
});

export const makePaymentSuccess = () => ({
  type: paymentActions.MAKE_PAYMENT_SUCCESS,
});

export const makePaymentError = () => ({
  type: paymentActions.MAKE_PAYMENT_ERROR,
});
