export const metaStatuses = {
  DEFAULT: "default",
  SUCCESS: "success",
  FAILURE: "failure",
  LOADING: "loading"
};

export const defaultMetaState = {
  status: metaStatuses.DEFAULT,
  error: ""
};
