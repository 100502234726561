import React from "react";
import { paths } from "../../../constants/paths";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../../constants/theme";
import { TrackClick } from "../../../utils/analytics";

const Wrapper = styled(Link)`
  display: block;
  background-color: ${colors.backgroundBlue};
  padding: 32px 24px;
  border-radius: 4px;
  text-align: center;
`;

const Underline = styled.span`
  text-decoration: underline;
`;

export const PowerSearchAd = ({ tracking }) => {
  const copy = "Discover 2000+ hidden remote jobs";

  return (
    <Wrapper
      to={paths.power_search}
      onClick={() => {
        TrackClick({
          category: "PowerSearch",
          action: tracking.action,
          label: `Listings Ad - ${copy}`,
        });
      }}
    >
      <Underline>{copy}</Underline> ⚡️
    </Wrapper>
  );
};
