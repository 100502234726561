export const setCategory = (category) => {
  if (category === "social media" || category === "seo") {
    return "marketing";
  }

  if (
    category === "recruiter" ||
    category === "sales" ||
    category === "hr" ||
    category === "project manager"
  ) {
    return "manager";
  }
  if (category === "editing" || category === "copywriter") {
    return "writing";
  }

  if (category === "") {
    return "all";
  }

  return category;
};
