export const filterJobs = (jobs, filters) => {
  let filteredJobs = jobs;
  for (let filter in filters) {
    filteredJobs = filteredJobs.filter(job => {      
      if (filters[filter]) {
        if (filter === 'technology') {          
          return filterTechnologies(job, filters[filter]);
        }
        if (filter === "job_country")
          return filterLocations(job, filters[filter]);
        return job[filter].toUpperCase() === filters[filter].toUpperCase();
      }
      return job;
    });
  }
  return filteredJobs;
};

const filterLocations = (job, locations) => {  
  let jobCountry = job.job_country;
  if (locations && locations.length > 0) {
    if (locations.some(locations => locations === jobCountry)) {
      return job;
    }
  } else {
    return job;
  }
};

const filterTechnologies = ( job, technologies ) => {
  let jobTechnologies = job.technology_list;

  if(jobTechnologies && jobTechnologies.length > 0 ){
    for(let tech in jobTechnologies) {     
      if(technologies.indexOf(jobTechnologies[tech].label) >= 0) {       
        return job;
      }
    }
  } else {
    return null;
  }
}



